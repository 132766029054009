import {
    DownOutlined,
    ExportOutlined,
    FolderAddOutlined,
    ImportOutlined,
    MinusOutlined,
    PlusOutlined,
} from '@ant-design/icons';
import { Button, Drawer, Dropdown, PageHeader } from 'antd';
import React from 'react';
import { DRAWER_WIDTH_EXTRA_LARGE, DRAWER_WIDTH_LARGE } from '../../../../config/constants';
import { translations } from '../../../../config/translations';
import { Filter } from '../../../../core/models/Filter';
import { ProjectItemExtendedVm } from '../../../../core/models/ProjectItems';
import { SelectOption } from '../../../../core/models/SelectOption';
import { ActionType, DrawerState, ModuleName } from '../../../../core/models/enum';
import { authorizeAction } from '../../../../helpers/CheckPermissionHelper';
import {
    getItemsMaxDepth,
    getItemIds,
    getItemsExpandLevel,
} from '../../../../helpers/ProjectItemHelper';
import { buildFilterRequest } from '../../../../helpers/RecursionHelper';
import { UnitOfMeasurementsClient, UnitOfMeasurementVm } from '../../../../utils/api';
import { Props } from './index';
import ProjectItemTable from './project-item-table';
import ProjectItemsExportForm from './project-items-export-form';
import ProjectItemsImportForm from './project-items-import-form';

interface State {
    drawerState: DrawerState;
    allowCreate: boolean;
    isImport?: boolean;
    expandedRowKeys?: React.Key[];
    expandedLevel: number;
    prevEditingItemId?: number;
    unitsOfMeasurement: SelectOption[];
    filter?: Filter;
    forceRenderExpandColumn?: boolean;
}

class ProjectItems extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            drawerState: DrawerState.Closed,
            allowCreate: false,
            unitsOfMeasurement: [],
            expandedLevel: 0,
        };
    }

    public componentDidMount = () => {
        this.getUnitsOfMeasurement();
        this.initExpandedRows();
        this.getPermissions();
    };

    public componentDidUpdate = (prevProps: Props, prevState: State) => {
        const { projectItems } = this.props;
        const { filter } = this.state;

        if (prevState.filter !== filter) {
            this.setState({
                forceRenderExpandColumn: true,
            });
        }

        if (!prevProps.projectItems && projectItems) {
            this.initExpandedRows();
        }
    };

    private getPermissions = async () => {
        const { userProfile } = this.props;

        const allowCreate = await authorizeAction(
            userProfile,
            ModuleName.ProjectItems,
            ActionType.Create
        );

        this.setState({
            allowCreate,
        });
    };

    private getUnitsOfMeasurement = async () => {
        const units = await new UnitOfMeasurementsClient().getAll();

        this.setState({
            unitsOfMeasurement: units.map((unit: UnitOfMeasurementVm): SelectOption => {
                return {
                    label: unit.shortName || unit.name,
                    value: unit.id,
                };
            }),
        });
    };

    private initExpandedRows = () => {
        const { projectItems } = this.props;

        if (!projectItems) return;
        const maxParentDepth = getItemsMaxDepth(projectItems) - 1;

        this.setState({
            expandedRowKeys: getItemIds(projectItems, maxParentDepth),
            expandedLevel: maxParentDepth,
            forceRenderExpandColumn: true,
        });
    };

    private handleImport = (isImport: boolean = true) => {
        this.setState({
            drawerState: DrawerState.Create,
            isImport,
        });
    };

    private handleCloseDrawer = () => {
        this.setState({
            drawerState: DrawerState.Closed,
        });
    };

    private handleAdd = (isGroup: boolean, parentId?: number) => {
        const { projectItems, editingItem, onAdd, onCancel } = this.props;
        const { expandedRowKeys, expandedLevel, filter } = this.state;

        if (editingItem) {
            onCancel();
        }

        if (filter) {
            this.handleFilterChange({});
        }

        const shouldExpandParent = parentId && !expandedRowKeys?.includes(parentId);

        if (shouldExpandParent) {
            const shouldExpandParent = parentId && !expandedRowKeys?.includes(parentId);
            const newExpandedRowKeys = shouldExpandParent
                ? [...(expandedRowKeys || []), parentId!]
                : expandedRowKeys;
            const newExpandedLevel = shouldExpandParent
                ? getItemsExpandLevel(projectItems || [], newExpandedRowKeys || [])
                : expandedLevel;

            this.setState(
                {
                    expandedRowKeys: newExpandedRowKeys,
                    expandedLevel: newExpandedLevel,
                    prevEditingItemId: editingItem?.id,
                    forceRenderExpandColumn: !!shouldExpandParent,
                },
                () => onAdd(isGroup, parentId)
            );
        } else {
            this.setState(
                {
                    prevEditingItemId: editingItem?.id,
                    forceRenderExpandColumn: !!shouldExpandParent,
                },
                () => onAdd(isGroup, parentId)
            );
        }
    };

    private handleEdit = (item: Partial<ProjectItemExtendedVm>) => {
        const { editingItem, onEdit } = this.props;

        onEdit(item);

        this.setState({
            prevEditingItemId: editingItem?.id,
            forceRenderExpandColumn: false,
        });
    };

    private handleCancel = () => {
        const { editingItem, onCancel } = this.props;

        onCancel();

        this.setState({
            prevEditingItemId: editingItem?.id,
            forceRenderExpandColumn: false,
        });
    };

    private handleSave = async (values: Partial<ProjectItemExtendedVm>) => {
        const { editingItem, onSave } = this.props;

        onSave(values);

        this.setState({
            prevEditingItemId: editingItem?.id,
        });
    };

    private handleFilterChange = (filters: any) => {
        const { editingItem } = this.props;
        const filter: Filter = {
            filterRequest: buildFilterRequest(filters),
        };

        if (editingItem) {
            this.handleCancel();
        }

        this.setState({
            filter: filter,
        });
    };

    private handleExpand = (expand: boolean, item: ProjectItemExtendedVm) => {
        const { projectItems } = this.props;
        const { expandedRowKeys } = this.state;

        const newExpandedRowKeys = expand
            ? [...(expandedRowKeys || []), item.id]
            : expandedRowKeys?.filter((k) => k !== item.id);

        this.setState({
            expandedRowKeys: newExpandedRowKeys,
            expandedLevel: getItemsExpandLevel(projectItems || [], newExpandedRowKeys || []),
            forceRenderExpandColumn: true,
        });
    };

    private handleToggleLevelExpand = (expand: boolean) => {
        const { projectItems } = this.props;
        const { expandedLevel } = this.state;

        if (!projectItems) return;

        const maxDepth = getItemsMaxDepth(projectItems) - 1;
        let newExpandedLevel = expand ? expandedLevel + 1 : expandedLevel - 1;

        if (newExpandedLevel > maxDepth) {
            newExpandedLevel = maxDepth;
        }

        let newExpandedRowKeys: React.Key[] = [];
        if (newExpandedLevel > -1) {
            newExpandedRowKeys = getItemIds(projectItems, newExpandedLevel);
        }

        this.setState({
            expandedRowKeys: newExpandedRowKeys,
            expandedLevel: newExpandedLevel,
            forceRenderExpandColumn: true,
        });
    };

    public render(): React.ReactElement {
        const {
            projectId,
            projectItems,
            projectSituationItems,
            editingItem,
            getProjectItems,
            onDelete,
            userProfile,
            loading,
        } = this.props;
        const {
            drawerState,
            isImport,
            expandedRowKeys,
            expandedLevel,
            prevEditingItemId,
            forceRenderExpandColumn,
            filter,
            unitsOfMeasurement,
            allowCreate,
        } = this.state;

        return (
            <>
                <PageHeader
                    title={
                        <>
                            <Button
                                size="small"
                                onClick={() => this.handleToggleLevelExpand(true)}
                                icon={<PlusOutlined />}
                            />
                            <Button
                                size="small"
                                onClick={() =>
                                    expandedLevel > -1 ? this.handleToggleLevelExpand(false) : null
                                }
                                icon={<MinusOutlined />}
                            />
                        </>
                    }
                    extra={
                        <>
                            {allowCreate && (
                                <Dropdown
                                    trigger={['click']}
                                    menu={{
                                        items: [
                                            {
                                                key: '1',
                                                onClick: () => this.handleAdd(false),
                                                icon: <PlusOutlined />,
                                                label: translations.projects.addItem,
                                            },
                                            {
                                                key: '2',
                                                onClick: () => this.handleAdd(true),
                                                icon: <FolderAddOutlined />,
                                                label: translations.projects.addGroup,
                                            },
                                        ],
                                    }}
                                >
                                    <Button type="primary" icon={<PlusOutlined />}>
                                        {translations.projects.addItem} <DownOutlined />
                                    </Button>
                                </Dropdown>
                            )}
                            <Button
                                type="primary"
                                onClick={() => this.handleImport()}
                                disabled={loading}
                                icon={<ImportOutlined />}
                            >
                                {translations.general.import}
                            </Button>

                            <Button
                                onClick={() => this.handleImport(false)}
                                disabled={loading || !projectItems?.length}
                                icon={<ExportOutlined />}
                            >
                                {translations.general.export}
                            </Button>
                        </>
                    }
                />

                <ProjectItemTable
                    projectId={projectId}
                    projectItems={projectItems}
                    projectSituationItems={projectSituationItems}
                    editingItem={editingItem}
                    prevEditingItemId={prevEditingItemId}
                    forceRenderExpandColumn={forceRenderExpandColumn}
                    expandedRowKeys={expandedRowKeys}
                    filter={filter}
                    onAdd={this.handleAdd}
                    onEdit={this.handleEdit}
                    onCancel={this.handleCancel}
                    onSave={this.handleSave}
                    onDelete={onDelete}
                    onExpand={this.handleExpand}
                    onFilterChange={this.handleFilterChange}
                    unitsOfMeasurement={unitsOfMeasurement}
                    userProfile={userProfile}
                />

                <Drawer
                    title={
                        isImport
                            ? translations.projects.items.import
                            : translations.projects.items.export
                    }
                    open={!!drawerState}
                    onClose={() => this.handleCloseDrawer()}
                    width={isImport ? DRAWER_WIDTH_LARGE : DRAWER_WIDTH_EXTRA_LARGE}
                    destroyOnClose
                >
                    {isImport ? (
                        <ProjectItemsImportForm
                            projectId={projectId}
                            onClose={() => this.handleCloseDrawer()}
                            onSuccess={() => {
                                this.handleCloseDrawer();
                                getProjectItems();
                            }}
                            isEditMode={!!projectItems?.length}
                        />
                    ) : (
                        <ProjectItemsExportForm
                            projectId={projectId}
                            onClose={() => this.handleCloseDrawer()}
                            onSuccess={() => this.handleCloseDrawer()}
                        />
                    )}
                </Drawer>
            </>
        );
    }
}

export default ProjectItems;
