import { Checkbox, DatePicker, Divider, Form, Input, InputNumber, Select } from 'antd';
import moment from 'moment';
import React from 'react';
import DrawerButtons from '../../../components/drawer-buttons';
import { defaultFormat } from '../../../config/constants';
import { formItemLayout1410 } from '../../../config/formLayouts';
import { translations } from '../../../config/translations';
import { showSuccess } from '../../../helpers/NotificationHelper';
import { create, update } from '../../../helpers/SubmitHelper';
import { oibValidator, dateRangeValidator } from '../../../helpers/ValidationHelper';
import {
    AssociateTypeEnum,
    CityVm,
    CodebooksClient,
    CompaniesClient,
    CooperatorVm,
    CooperatorsClient,
    CountriesClient,
    CountryVm,
    EmployeeDetailVm,
    EmployeesClient,
    SelectOptionVm,
} from '../../../utils/api';
import { Props } from './index';
import { SelectOption } from '../../../core/models/SelectOption';
import SelectCity from '../../../components/select-city/SelectCity';

interface State {
    isSaving: boolean;
    genders?: SelectOptionVm[];
    countries: SelectOption[];
    cities: SelectOption[];
    temporaryCities: SelectOption[];
    employmentAgencies?: SelectOption[];
    roles?: SelectOptionVm[];
    companies: SelectOption[];
    selectedCompanyId: number;
    filteredCompanies: SelectOption[];
}

class EmployeeForm extends React.Component<Props, State> {
    private formRef: any;

    public constructor(props: Props) {
        super(props);

        this.formRef = React.createRef();

        this.state = {
            isSaving: false,
            countries: [],
            cities: [],
            temporaryCities: [],
            companies: [],
            selectedCompanyId: props.employee?.companyId ?? 0,
            filteredCompanies: [],
        };
    }

    public componentDidMount = () => {
        this.getGenders();
        this.getCountries();
        this.getAgencies();
        this.getRoles();
        this.getCompanies();
    };

    private getGenders = async () => {
        this.setState({
            genders: await new CodebooksClient().getByCodebookName('Gender'),
        });
    };

    private getCountries = async () => {
        const { employee } = this.props;

        if (employee?.countryId) {
            this.getCountryCities(employee?.countryId);
        }
        if (employee?.temporaryCountryId) {
            this.getTemporaryCountryCities(employee?.temporaryCountryId);
        }

        this.setState({
            countries: (await new CountriesClient().getAll()).map(
                (unit: CountryVm): SelectOption => ({
                    label: unit.name,
                    value: unit.id,
                })
            ),
        });
    };

    private getCompanies = async () => {
        const companies = (await new CompaniesClient().getAll()).map((unit) => ({
            label: unit.name,
            value: unit.id,
        }));

        const { selectedCompanyId } = this.state;

        this.setState({
            companies,
            filteredCompanies:
                selectedCompanyId !== 0
                    ? companies.filter((company) => company.value !== selectedCompanyId)
                    : companies,
        });
    };

    private getCountryCities = async (countryId: number) => {
        this.setState({
            cities: (await new CountriesClient().getById(countryId)).cities.map(
                (unit: CityVm): SelectOption => ({
                    label: unit.name,
                    value: unit.id,
                })
            ),
        });
    };

    private handleCompanyChange = (value: number) => {
        this.setState({
            selectedCompanyId: value,
            filteredCompanies: this.state.companies.filter((company) => company.value !== value),
        });
    };

    private getTemporaryCountryCities = async (countryId: number) => {
        this.setState({
            temporaryCities: (await new CountriesClient().getById(countryId)).cities.map(
                (unit: CityVm): SelectOption => ({
                    label: unit.name,
                    value: unit.id,
                })
            ),
        });
    };

    private getAgencies = async () => {
        this.setState({
            employmentAgencies: (
                await new CooperatorsClient().getByAssociateType(AssociateTypeEnum.EmploymentAgency)
            ).map(
                (unit: CooperatorVm): SelectOption => ({
                    label: unit.name,
                    value: unit.id,
                })
            ),
        });
    };

    private getRoles = async () => {
        this.setState({
            roles: await new CodebooksClient().getByCodebookName('EmployeeRole'),
        });
    };

    private handleCityAdded = () => {
        const countryId = this.formRef?.current?.getFieldValue('countryId');
        const temporaryCountryId = this.formRef?.current?.getFieldValue('temporaryCountryId');

        if (countryId) {
            this.getCountryCities(countryId);
        }
        if (temporaryCountryId) {
            this.getTemporaryCountryCities(temporaryCountryId);
        }
    };

    private handleSuccess = (result: EmployeeDetailVm) => {
        const { onSuccess } = this.props;

        showSuccess(translations.employees.successfullySaved);

        onSuccess(result);
    };

    private handleSubmit = (values: any) => {
        const { employee } = this.props;
        const employeeClient = new EmployeesClient();

        this.setState({
            isSaving: true,
        });

        const request = {
            ...values,
            email: values.email ? values.email : undefined, // send undefined instead of empty string
            id: employee ? employee.id : undefined,
        };

        if (request.id) {
            update(employeeClient, request, this.handleSuccess);
        } else {
            create(employeeClient, request, this.handleSuccess);
        }

        this.setState({
            isSaving: false,
        });
    };

    public render() {
        const { employee, onClose } = this.props;
        const {
            isSaving,
            temporaryCities,
            countries,
            cities,
            genders,
            employmentAgencies,
            roles,
            companies,
            filteredCompanies,
        } = this.state;

        const countryId = this.formRef?.current?.getFieldValue('countryId');
        const temporaryCountryId = this.formRef?.current?.getFieldValue('temporaryCountryId');

        return (
            <Form onFinish={this.handleSubmit} {...formItemLayout1410} ref={this.formRef}>
                <DrawerButtons isSaving={isSaving} onCancelAction={onClose} />

                <Divider>{translations.employees.generalInfo}</Divider>

                <Form.Item
                    name="name"
                    label={translations.employees.name}
                    initialValue={employee?.name}
                    rules={[
                        {
                            required: true,
                            message: translations.general.requiredField,
                        },
                        { max: 200, message: translations.general.maxLength200 },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="surname"
                    label={translations.employees.surname}
                    initialValue={employee?.surname}
                    rules={[
                        {
                            required: true,
                            message: translations.general.requiredField,
                        },
                        { max: 200, message: translations.general.maxLength200 },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="oib"
                    label={translations.employees.oib}
                    initialValue={employee?.oib}
                    rules={[
                        {
                            required: true,
                            message: translations.general.requiredField,
                        },
                        {
                            validator: (_rule: any, value: any, callback: any) =>
                                oibValidator(
                                    _rule,
                                    value,
                                    callback,
                                    translations.general.requiredField,
                                    employee?.oib
                                ),
                            message: translations.employees.oibInvalid,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="identificationNumber"
                    label={translations.employees.identificationNumber}
                    initialValue={employee?.identificationNumber}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="birthDate"
                    label={translations.employees.birthDate}
                    initialValue={employee?.birthDate ? moment(employee.birthDate) : null}
                    rules={[
                        {
                            required: true,
                            message: translations.general.requiredField,
                        },
                    ]}
                >
                    <DatePicker
                        placeholder={translations.general.chooseDate}
                        format={defaultFormat}
                        style={{ width: '100%' }}
                        allowClear
                    />
                </Form.Item>

                <Form.Item
                    name="genderId"
                    label={translations.employees.gender}
                    initialValue={employee?.genderId}
                    rules={[
                        {
                            required: true,
                            message: translations.general.requiredField,
                        },
                    ]}
                >
                    <Select<number>
                        placeholder={translations.employees.chooseGender}
                        options={genders}
                    />
                </Form.Item>

                <Form.Item
                    name="email"
                    label={translations.employees.email}
                    initialValue={employee?.email}
                    rules={[
                        { type: 'email', message: translations.general.invalidEmail },
                        { max: 200, message: translations.general.maxLength200 },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="phone"
                    label={translations.employees.phone}
                    initialValue={employee?.phone}
                    rules={[
                        {
                            required: true,
                            message: translations.general.requiredField,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Divider>{translations.employees.residenceAddress}</Divider>

                <Form.Item
                    name="countryId"
                    label={translations.employees.country}
                    initialValue={employee?.countryId}
                    rules={[
                        {
                            required: true,
                            message: translations.general.requiredField,
                        },
                    ]}
                >
                    <Select<number>
                        showSearch
                        allowClear
                        placeholder={translations.employees.countryPlaceholder}
                        options={countries}
                        onSelect={(value) => this.getCountryCities(value)}
                    />
                </Form.Item>
                <Form.Item
                    name="cityId"
                    label={translations.employees.city}
                    initialValue={employee?.cityId}
                    rules={[
                        {
                            required: true,
                            message: translations.general.requiredField,
                        },
                    ]}
                >
                    <SelectCity
                        showSearch
                        allowClear
                        placeholder={translations.employees.cityPlaceholder}
                        options={cities}
                        countryId={countryId}
                        onCityAdded={this.handleCityAdded}
                    />
                </Form.Item>
                <Form.Item
                    name="address"
                    label={translations.employees.address}
                    initialValue={employee?.address}
                    rules={[
                        { required: true, message: translations.general.requiredField },
                        { max: 200, message: translations.general.maxLength200 },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Divider>{translations.employees.inhabitancyAddress}</Divider>

                <Form.Item
                    name="temporaryCountryId"
                    label={translations.employees.country}
                    initialValue={employee?.temporaryCountryId}
                >
                    <Select<number>
                        showSearch
                        allowClear
                        placeholder={translations.employees.countryPlaceholder}
                        options={countries}
                        onSelect={(value) => this.getTemporaryCountryCities(value)}
                    />
                </Form.Item>
                <Form.Item
                    name="temporaryCityId"
                    label={translations.employees.city}
                    initialValue={employee?.temporaryCityId}
                >
                    <SelectCity
                        showSearch
                        allowClear
                        placeholder={translations.employees.cityPlaceholder}
                        options={temporaryCities}
                        countryId={temporaryCountryId}
                        onCityAdded={this.handleCityAdded}
                    />
                </Form.Item>

                <Form.Item
                    name="temporaryAddress"
                    label={translations.employees.address}
                    initialValue={employee?.temporaryAddress}
                    rules={[{ max: 200, message: translations.general.maxLength200 }]}
                >
                    <Input />
                </Form.Item>

                <Divider>{translations.employees.positionInfo}</Divider>

                <Form.Item
                    name="companyId"
                    label={translations.employees.company}
                    initialValue={employee?.companyId}
                >
                    <Select<number>
                        showSearch
                        allowClear
                        placeholder={translations.employees.companyPlaceholder}
                        options={companies}
                        onChange={this.handleCompanyChange}
                    />
                </Form.Item>

                <Form.Item
                    name="company2Id"
                    label={translations.employees.company2}
                    initialValue={employee?.company2Id}
                >
                    <Select<number>
                        showSearch
                        allowClear
                        placeholder={translations.employees.companyPlaceholder}
                        options={filteredCompanies}
                    />
                </Form.Item>

                <Form.Item
                    name="employeeRoleId"
                    label={translations.employees.role}
                    initialValue={employee?.employeeRoleId}
                    rules={[
                        {
                            required: true,
                            message: translations.general.requiredField,
                        },
                    ]}
                >
                    <Select<number>
                        placeholder={translations.employees.chooseRole}
                        options={roles}
                    />
                </Form.Item>

                <Form.Item
                    name="position"
                    label={translations.employees.position}
                    initialValue={employee?.position}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="activeFrom"
                    label={translations.employees.activeFrom}
                    initialValue={employee?.activeFrom ? moment(employee.activeFrom) : null}
                    rules={[
                        {
                            required: true,
                            message: translations.general.requiredField,
                        },
                        {
                            validator: (_rule: any, value: any, callback: any) =>
                                dateRangeValidator(
                                    callback,
                                    translations.general.fromBeforeToValidationMessage,
                                    value,
                                    this.formRef?.current?.getFieldValue('activeTo')
                                ),
                        },
                    ]}
                >
                    <DatePicker
                        placeholder={translations.general.chooseDate}
                        format={defaultFormat}
                        style={{ width: '100%' }}
                        allowClear
                    />
                </Form.Item>
                <Form.Item
                    name="activeTo"
                    label={translations.employees.activeTo}
                    initialValue={employee?.activeTo ? moment(employee.activeTo) : null}
                    rules={[
                        {
                            validator: (_rule: any, value: any, callback: any) =>
                                dateRangeValidator(
                                    callback,
                                    translations.general.fromBeforeToValidationMessage,
                                    this.formRef?.current?.getFieldValue('activeFrom'),
                                    value
                                ),
                        },
                    ]}
                >
                    <DatePicker
                        placeholder={translations.general.chooseDate}
                        format={defaultFormat}
                        style={{ width: '100%' }}
                        allowClear
                    />
                </Form.Item>

                <Form.Item
                    name="agencyId"
                    label={translations.employees.employmentAgency}
                    initialValue={employee?.agencyId}
                >
                    <Select allowClear options={employmentAgencies} />
                </Form.Item>

                <Form.Item
                    className="bonus-amount"
                    name="usedBonusAmountBeforeEmployment"
                    label={translations.employees.usedBonusAmountBeforeEmployment}
                    initialValue={employee?.usedBonusAmountBeforeEmployment}
                    style={{ justifyContent: 'center' }}
                >
                    <InputNumber precision={2} style={{ width: '100%' }} decimalSeparator="," />
                </Form.Item>

                <Divider>{translations.employees.workSafetyInfo}</Divider>

                <Form.Item
                    name="isSupervised"
                    valuePropName="checked"
                    label={translations.employees.workUnderSupervision}
                    initialValue={employee ? employee.isSupervised : undefined}
                >
                    <Checkbox />
                </Form.Item>

                <Form.Item
                    name="supervisionDecisionExpirationDate"
                    label={translations.employees.workUnderSupervisionExpiryDate}
                    initialValue={
                        employee?.supervisionDecisionExpirationDate
                            ? moment(employee.supervisionDecisionExpirationDate)
                            : null
                    }
                >
                    <DatePicker
                        placeholder={translations.general.chooseDate}
                        format={defaultFormat}
                        style={{ width: '100%' }}
                        allowClear
                    />
                </Form.Item>
                <Form.Item
                    name="isCompetentForSafeWork"
                    valuePropName="checked"
                    label={translations.employees.competenceForWorkInSafeManner}
                    initialValue={employee ? employee.isCompetentForSafeWork : undefined}
                >
                    <Checkbox />
                </Form.Item>
                <Form.Item
                    name="isCompetentForFireProtection"
                    valuePropName="checked"
                    label={translations.employees.competenceForFireProtectionMeasures}
                    initialValue={employee ? employee.isCompetentForFireProtection : undefined}
                >
                    <Checkbox />
                </Form.Item>

                <Form.Item
                    name="isCompetentForFirstAid"
                    valuePropName="checked"
                    label={translations.employees.competenceForFirstAid}
                    initialValue={employee ? employee.isCompetentForFirstAid : undefined}
                >
                    <Checkbox />
                </Form.Item>

                <Form.Item
                    name="isAuthorizedForWorkSafety"
                    valuePropName="checked"
                    label={translations.employees.workSafetyAuthorizee}
                    initialValue={employee ? employee.isAuthorizedForWorkSafety : undefined}
                >
                    <Checkbox />
                </Form.Item>
                <Form.Item
                    name="authorizationExpirationDate"
                    label={translations.employees.workSafetyAuthorizationExpiryDate}
                    initialValue={
                        employee?.authorizationExpirationDate
                            ? moment(employee.authorizationExpirationDate)
                            : null
                    }
                >
                    <DatePicker
                        placeholder={translations.general.chooseDate}
                        format={defaultFormat}
                        style={{ width: '100%' }}
                        allowClear
                    />
                </Form.Item>

                <Divider>{translations.employees.medicalExaminationInfo}</Divider>

                <Form.Item
                    name="isMedicalExaminationRequired"
                    valuePropName="checked"
                    label={translations.employees.medicalExaminationRequired}
                    initialValue={employee ? employee.isMedicalExaminationRequired : undefined}
                >
                    <Checkbox />
                </Form.Item>
                <Form.Item
                    name="medicalExaminationExpirationDate"
                    label={translations.employees.medicalExaminationExpiryDate}
                    initialValue={
                        employee?.medicalExaminationExpirationDate
                            ? moment(employee.medicalExaminationExpirationDate)
                            : null
                    }
                >
                    <DatePicker
                        placeholder={translations.general.chooseDate}
                        format={defaultFormat}
                        style={{ width: '100%' }}
                        allowClear
                    />
                </Form.Item>
                <Form.Item
                    name="medicalExaminationBy"
                    label={translations.employees.medicalExaminationScheduled}
                    initialValue={
                        employee && employee.medicalExaminationBy
                            ? employee.medicalExaminationBy
                            : undefined
                    }
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="medicalExaminationNotes"
                    label={translations.employees.medicalExaminationNotes}
                    initialValue={
                        employee && employee.medicalExaminationNotes
                            ? employee.medicalExaminationNotes
                            : undefined
                    }
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="pointList"
                    label={translations.employees.medicalExaminationSections}
                    initialValue={employee && employee.pointList ? employee.pointList : undefined}
                >
                    <Input />
                </Form.Item>

                <Divider>{translations.employees.toxicologyInfo}</Divider>

                <Form.Item
                    name="isCompetentForToxicology"
                    valuePropName="checked"
                    label={translations.employees.toxicologyCompetence}
                    initialValue={employee ? employee.isCompetentForToxicology : undefined}
                >
                    <Checkbox />
                </Form.Item>
                <Form.Item
                    name="isResponsibleForToxicology"
                    valuePropName="checked"
                    label={translations.employees.responsibleForToxicology}
                    initialValue={employee ? employee.isResponsibleForToxicology : undefined}
                >
                    <Checkbox />
                </Form.Item>
                <Form.Item
                    name="isExecutantForToxicology"
                    valuePropName="checked"
                    label={translations.employees.executantForToxicology}
                    initialValue={employee ? employee.isExecutantForToxicology : undefined}
                >
                    <Checkbox />
                </Form.Item>
                <Form.Item
                    name="toxicologyExpirationDate"
                    label={translations.employees.toxicologyExpiryDate}
                    initialValue={
                        employee?.toxicologyExpirationDate
                            ? moment(employee.toxicologyExpirationDate)
                            : null
                    }
                >
                    <DatePicker
                        placeholder={translations.general.chooseDate}
                        format={defaultFormat}
                        style={{ width: '100%' }}
                        allowClear
                    />
                </Form.Item>
                <Form.Item
                    name="workShoesSize"
                    label={translations.employees.workShoesSize}
                    initialValue={
                        employee && employee.workShoesSize ? employee.workShoesSize : undefined
                    }
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="workPantsSize"
                    label={translations.employees.workPantsSize}
                    initialValue={
                        employee && employee.workPantsSize ? employee.workPantsSize : undefined
                    }
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="workJacketSize"
                    label={translations.employees.workJacketSize}
                    initialValue={
                        employee && employee.workJacketSize ? employee.workJacketSize : undefined
                    }
                >
                    <Input />
                </Form.Item>

                <Divider>{translations.employees.workPermitInfo}</Divider>

                <Form.Item
                    name="hasWorkingPermission"
                    valuePropName="checked"
                    label={translations.employees.workPermit}
                    initialValue={employee ? employee.hasWorkingPermission : undefined}
                >
                    <Checkbox />
                </Form.Item>
                <Form.Item
                    name="workingPermissionExpirationDate"
                    label={translations.employees.workPermitExpiryDate}
                    initialValue={
                        employee?.workingPermissionExpirationDate
                            ? moment(employee?.workingPermissionExpirationDate)
                            : null
                    }
                >
                    <DatePicker
                        placeholder={translations.general.chooseDate}
                        format={defaultFormat}
                        style={{ width: '100%' }}
                        allowClear
                    />
                </Form.Item>
                <Form.Item
                    name="workingPermissionExtensionStartDate"
                    label={translations.employees.workPermitExtensionRequestDeadlineDate}
                    initialValue={
                        employee?.workingPermissionExtensionStartDate
                            ? moment(employee.workingPermissionExtensionStartDate)
                            : null
                    }
                >
                    <DatePicker
                        placeholder={translations.general.chooseDate}
                        format={defaultFormat}
                        style={{ width: '100%' }}
                        allowClear
                    />
                </Form.Item>
                <Form.Item
                    name="workingPermissionExtensionRequestDate"
                    label={translations.employees.workPermitExtensionRequestSubmitDate}
                    initialValue={
                        employee?.workingPermissionExtensionRequestDate
                            ? moment(employee.workingPermissionExtensionRequestDate)
                            : null
                    }
                >
                    <DatePicker
                        placeholder={translations.general.chooseDate}
                        format={defaultFormat}
                        style={{ width: '100%' }}
                        allowClear
                    />
                </Form.Item>
            </Form>
        );
    }
}

export default EmployeeForm;
