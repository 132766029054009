import {
    CheckOutlined,
    CloseOutlined,
    DeleteOutlined,
    DownOutlined,
    EditOutlined,
    EllipsisOutlined,
    FolderOutlined,
    RightOutlined,
} from '@ant-design/icons';
import { Button, Dropdown, Form, MenuProps, Space, Table, Tooltip } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { ExpandableConfig } from 'antd/lib/table/interface';
import { RenderExpandIconProps } from 'rc-table/lib/interface';
import React from 'react';
import InputPrice from '../../../../../components/input-price';
import { translations } from '../../../../../config/translations';
import { ProjectItemExtendedVm, SubcontractorItem } from '../../../../../core/models/ProjectItems';
import { ActionType, ModuleName } from '../../../../../core/models/enum';
import { authorizeAction } from '../../../../../helpers/CheckPermissionHelper';
import { getSearchFilter } from '../../../../../helpers/FilterHelper';
import { showConfirm } from '../../../../../helpers/NotificationHelper';
import { formatPrice } from '../../../../../helpers/PriceHelper';
import { connectContractorItems, sumPropValues } from '../../../../../helpers/ProjectItemHelper';
import { recursiveFilterAndSort } from '../../../../../helpers/RecursionHelper';
import { getTableLocale } from '../../../../../helpers/TableHelper';
import { ProjectCooperatorVm, UnitOfMeasurementVm } from '../../../../../utils/api';
import { Props } from './index';

const NON_TABLE_CONTENT_HEIGHT = 400;

interface State {
    allowCreate: boolean;
    allowUpdate: boolean;
    allowDelete: boolean;
}

class ProjectContractorItemTable extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            allowCreate: false,
            allowUpdate: false,
            allowDelete: false,
        };
    }

    public componentDidMount = () => {
        document.addEventListener('keydown', this.handleKeyDown);
        this.getPermissions();
    };

    public componentWillUnmount = () => {
        document.removeEventListener('keydown', this.handleKeyDown);
    };

    private getPermissions = async () => {
        const { userProfile } = this.props;

        const allowUpdate = await authorizeAction(
            userProfile,
            ModuleName.ProjectCooperatorItems,
            ActionType.Update
        );
        const allowDelete = await authorizeAction(
            userProfile,
            ModuleName.ProjectCooperatorItems,
            ActionType.Delete
        );
        const allowCreate = await authorizeAction(
            userProfile,
            ModuleName.ProjectCooperatorItems,
            ActionType.Create
        );

        this.setState({
            allowUpdate,
            allowCreate,
            allowDelete,
        });
    };

    private handleKeyDown: { (e: KeyboardEvent): void } = (e: KeyboardEvent) => {
        const { onCancel } = this.props;

        if (e.key === 'Escape') {
            onCancel();
        }
    };

    private getTableColumns = (): ColumnProps<ProjectItemExtendedVm>[] => {
        const {
            editingContractorItem,
            activeContractor,
            activeSubcontractors,
            forceRenderContractorColumns,
            prevEditingItemId,
            onAdd,
            onEdit,
            onCancel,
            onDelete,
        } = this.props;
        const { allowDelete, allowUpdate, allowCreate } = this.state;

        const subcontractedGroupColumns = activeSubcontractors?.map(
            (subcontractor: ProjectCooperatorVm) => ({
                title: `${translations.projects.items.subcontracted} (${subcontractor.cooperatorName})`,
                key: `subcontractor-${subcontractor.id}-group`,
                className: 'border-right',
                children: [
                    {
                        title: translations.projects.quantity,
                        key: `subcontractor-${subcontractor.id}-quantity`,
                        width: 110,
                        align: 'right',
                        render: (_: number | undefined, item: ProjectItemExtendedVm) => {
                            if (item.isGroup) return null;

                            const subcontractorItem = item.subcontractorItems?.find(
                                (subcontractorItem: SubcontractorItem) =>
                                    subcontractorItem.projectCooperatorId === subcontractor.id
                            );

                            if (!subcontractorItem) return null;

                            return subcontractorItem.quantity
                                ? formatPrice(subcontractorItem.quantity, 6)
                                : '0,00';
                        },
                        shouldCellUpdate: () => false,
                    },
                    {
                        title: translations.projects.unitPrice,
                        dataIndex: 'unitPrice',
                        width: 110,
                        align: 'right',
                        render: (_: number | undefined, item: ProjectItemExtendedVm) => {
                            if (item.isGroup) return null;

                            const subcontractorItem = item.subcontractorItems?.find(
                                (subcontractorItem: SubcontractorItem) =>
                                    subcontractorItem.projectCooperatorId === subcontractor.id
                            );

                            if (!subcontractorItem) return null;

                            return subcontractorItem.unitPrice
                                ? formatPrice(subcontractorItem.unitPrice)
                                : '0,00';
                        },
                        shouldCellUpdate: () => false,
                    },
                    {
                        title: translations.projects.totalPrice,
                        dataIndex: 'totalPrice',
                        width: 110,
                        align: 'right',
                        className: 'border-right',
                        render: (_: number | undefined, item: ProjectItemExtendedVm) => {
                            const subcontractorItem = item.subcontractorItems?.find(
                                (subcontractorItem: SubcontractorItem) =>
                                    subcontractorItem.projectCooperatorId === subcontractor.id
                            );

                            if (!subcontractorItem) return null;

                            return subcontractorItem.totalPrice
                                ? formatPrice(subcontractorItem.totalPrice)
                                : null;
                        },

                        shouldCellUpdate: () => false,
                    },
                ],
            })
        );

        const getActionItems = (item: ProjectItemExtendedVm): MenuProps['items'] => {
            let items: MenuProps['items'] = [];

            if (item.contractorItem) {
                if (allowUpdate) {
                    items.push({
                        key: 'edit',
                        onClick: (e) => {
                            e.domEvent.stopPropagation();
                            onEdit(item.contractorItem!);
                        },
                        icon: <EditOutlined />,
                        label: translations.projects.editItem,
                    });
                }
                if (allowDelete) {
                    items.push({
                        key: 'delete',
                        onClick: (e) => {
                            e.domEvent.stopPropagation();
                            showConfirm(
                                () => onDelete(item),
                                translations.projects.deleteItemConfirm,
                                translations.general.delete,
                                true
                            );
                        },
                        icon: <DeleteOutlined />,
                        label: translations.projects.deleteItem,
                        danger: true,
                    });
                }
            } else {
                if (allowCreate) {
                    items.push({
                        key: 'add',
                        onClick: (e) => {
                            e.domEvent.stopPropagation();
                            onAdd(item.id, activeContractor.id);
                        },
                        icon: <EditOutlined />,
                        label: translations.projects.editItem,
                    });
                }
            }

            return items;
        };

        return [
            {
                title: translations.projects.ordinal,
                dataIndex: 'ordinalDisplay',
                width: 80,
                fixed: 'left',
            },
            {
                title: translations.projects.item,
                dataIndex: 'name',
                width: 200,
                fixed: 'left',
                ellipsis: true,
                ...getSearchFilter(),
                render: (value: string | undefined, item: ProjectItemExtendedVm) => (
                    <>
                        {item.isGroup && (
                            <FolderOutlined style={{ marginLeft: 4, marginRight: 4 }} />
                        )}
                        <Tooltip title={value}>{value}</Tooltip>
                    </>
                ),
            },
            {
                title: translations.projects.unit,
                dataIndex: 'unitOfMeasurement',
                className: 'border-right',
                width: 80,
                fixed: 'left',
                render: (value: UnitOfMeasurementVm | undefined, item: ProjectItemExtendedVm) =>
                    item.isGroup ? null : value?.shortName,
            },
            // {
            //     title: `${translations.projects.items.title}`,
            //     key: 'item-group',
            //     className: 'border-right',
            //     children: [
            //         {
            //             title: translations.projects.quantity,
            //             dataIndex: 'quantity',
            //             width: 110,
            //             align: 'right',
            //             render: (value: number | undefined, item: ProjectItemExtendedVm) => {
            //                 if (item.isGroup) return null;

            //                 return value ? formatPrice(value, 6) : '0,00';
            //             },
            //             shouldCellUpdate: () => false,
            //         },
            //         {
            //             title: translations.projects.unitPrice,
            //             dataIndex: 'unitPrice',
            //             width: 110,
            //             align: 'right',
            //             render: (value: number | undefined, item: ProjectItemExtendedVm) => {
            //                 if (item.isGroup) return null;

            //                 return value ? formatPrice(value) : '0,00';
            //             },
            //             shouldCellUpdate: () => false,
            //         },
            //         {
            //             title: translations.projects.totalPrice,
            //             dataIndex: 'totalPrice',
            //             width: 110,
            //             align: 'right',
            //             className: 'border-right',
            //             render: (value: number | undefined) =>
            //                 value ? formatPrice(value) : '0,00',
            //             shouldCellUpdate: () => false,
            //         },
            //     ],
            // },
            {
                title: `${translations.projects.items.contracted}${
                    activeContractor.clientId ? ` (${activeContractor.clientName})` : ''
                }`,
                key: 'contractor-group',
                className: 'border-right',
                children: [
                    {
                        title: translations.projects.quantity,
                        dataIndex: 'contractorQuantity',
                        width: 110,
                        align: 'right',
                        render: (value: number | undefined, item: ProjectItemExtendedVm) => {
                            if (item.isGroup) return null;

                            if (editingContractorItem?.projectItemId === item.id) {
                                return (
                                    <Form.Item
                                        name="quantity"
                                        initialValue={editingContractorItem.quantity}
                                        rules={[
                                            {
                                                required: true,
                                                message: translations.general.requiredField,
                                            },
                                        ]}
                                    >
                                        <InputPrice
                                            placeholder={translations.projects.quantity}
                                            onClick={(e: React.MouseEvent) => e.stopPropagation()}
                                            onChange={(
                                                value: string | number | null | undefined
                                            ) => {
                                                onEdit({
                                                    ...editingContractorItem,
                                                    quantity: value == null ? undefined : +value,
                                                });
                                            }}
                                            precision={6}
                                        />
                                    </Form.Item>
                                );
                            }
                            return value ? formatPrice(value, 6) : null;
                        },
                        shouldCellUpdate: (item: ProjectItemExtendedVm) => {
                            return (
                                !item.isGroup &&
                                (editingContractorItem?.projectItemId === item.id ||
                                    prevEditingItemId === item.id ||
                                    forceRenderContractorColumns)
                            );
                        },
                    },
                    {
                        title: translations.projects.unitPrice,
                        dataIndex: 'contractorUnitPrice',
                        width: 110,
                        align: 'right',
                        render: (value: number | undefined, item: ProjectItemExtendedVm) => {
                            if (item.isGroup) return null;

                            if (editingContractorItem?.projectItemId === item.id) {
                                return (
                                    <Form.Item
                                        name="unitPrice"
                                        initialValue={editingContractorItem.unitPrice}
                                        rules={[
                                            {
                                                required: true,
                                                message: translations.general.requiredField,
                                            },
                                        ]}
                                    >
                                        <InputPrice
                                            placeholder={translations.projects.unitPrice}
                                            onClick={(e: React.MouseEvent) => e.stopPropagation()}
                                            onChange={(
                                                value: string | number | null | undefined
                                            ) => {
                                                onEdit({
                                                    ...editingContractorItem,
                                                    unitPrice: value == null ? undefined : +value,
                                                });
                                            }}
                                            precision={6}
                                        />
                                    </Form.Item>
                                );
                            }
                            return value ? formatPrice(value) : null;
                        },
                        shouldCellUpdate: (item: ProjectItemExtendedVm) => {
                            return (
                                !item.isGroup &&
                                (editingContractorItem?.projectItemId === item.id ||
                                    prevEditingItemId === item.id ||
                                    forceRenderContractorColumns)
                            );
                        },
                    },
                    {
                        title: translations.projects.totalPrice,
                        dataIndex: 'contractorTotalPrice',
                        width: 110,
                        align: 'right',
                        className: 'border-right',
                        render: (value: number | undefined, item: ProjectItemExtendedVm) => {
                            if (editingContractorItem?.projectItemId === item.id) {
                                return formatPrice(
                                    (editingContractorItem?.quantity || 0) *
                                        (editingContractorItem?.unitPrice || 0)
                                );
                            }

                            return value ? formatPrice(value) : null;
                        },
                        shouldCellUpdate: (
                            item: ProjectItemExtendedVm,
                            prevItem: ProjectItemExtendedVm
                        ) => {
                            return (
                                editingContractorItem?.projectItemId === item.id ||
                                prevEditingItemId === item.id ||
                                forceRenderContractorColumns ||
                                item.contractorTotalPrice !== prevItem.contractorTotalPrice
                            );
                        },
                    },
                ],
            },
            ...(subcontractedGroupColumns || []),
            {
                title: translations.general.actions,
                key: 'actions',
                width: 70,
                align: 'center',
                render: (item: ProjectItemExtendedVm) => {
                    if (item.isGroup) return null;

                    if (editingContractorItem?.projectItemId === item.id) {
                        return (
                            <Space>
                                <Button
                                    shape="circle"
                                    type="primary"
                                    size="small"
                                    htmlType="submit"
                                    onClick={(e) => e.stopPropagation()}
                                    icon={<CheckOutlined />}
                                />
                                <Button
                                    shape="circle"
                                    size="small"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        onCancel();
                                    }}
                                    icon={<CloseOutlined />}
                                />
                            </Space>
                        );
                    }

                    return (
                        <>
                            {(allowUpdate || allowDelete || allowCreate) && (
                                <Dropdown
                                    trigger={['click']}
                                    menu={{ items: getActionItems(item) }}
                                >
                                    <Button
                                        shape="circle"
                                        type="text"
                                        size="small"
                                        onClick={(e) => e.stopPropagation()}
                                        icon={<EllipsisOutlined />}
                                    />
                                </Dropdown>
                            )}
                        </>
                    );
                },
            },
        ];
    };

    private getTableExpandableConfig = (): ExpandableConfig<ProjectItemExtendedVm> => {
        const { editingContractorItem, expandedRowKeys, onExpand } = this.props;

        return {
            expandedRowKeys: expandedRowKeys,
            onExpand: (expanded: boolean, item: ProjectItemExtendedVm) => onExpand(expanded, item),
            expandIconColumnIndex: 1,
            expandIcon: (iconProps: RenderExpandIconProps<ProjectItemExtendedVm>) => {
                const { expanded, record, onExpand } = iconProps;

                if (
                    !record.children?.length ||
                    editingContractorItem?.projectItemId === record.id
                ) {
                    return null;
                }

                return (
                    <Button
                        shape="circle"
                        type="text"
                        size="small"
                        onClick={(e) => {
                            onExpand(record, e);
                        }}
                        icon={expanded ? <DownOutlined /> : <RightOutlined />}
                    />
                );
            },
        };
    };

    private getTableSummary = (data: readonly ProjectItemExtendedVm[]): React.ReactNode => {
        const { activeSubcontractors } = this.props;

        const contractorTotalPrice = sumPropValues(data, 'contractorTotalPrice');

        return (
            <Table.Summary fixed>
                <Table.Summary.Row>
                    <Table.Summary.Cell
                        index={0}
                        colSpan={3}
                        align="right"
                        className="border-right"
                    >
                        <strong style={{ textTransform: 'uppercase' }}>
                            {translations.general.total}
                        </strong>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell
                        index={6}
                        colSpan={3}
                        align="right"
                        className="border-right"
                    >
                        <strong>
                            {contractorTotalPrice ? formatPrice(contractorTotalPrice) : '0,00'}
                        </strong>
                    </Table.Summary.Cell>

                    {activeSubcontractors?.map((subcontractor: ProjectCooperatorVm) => {
                        const subcontractorItems = data.flatMap((item: ProjectItemExtendedVm) => {
                            const subcontractorItem = item.subcontractorItems?.find(
                                (pci: SubcontractorItem) =>
                                    pci.projectCooperatorId === subcontractor.id
                            );

                            if (!subcontractorItem) return [];

                            return [subcontractorItem];
                        });

                        return (
                            <Table.Summary.Cell
                                key={subcontractor.id}
                                index={6}
                                colSpan={3}
                                align="right"
                                className="border-right"
                            >
                                <strong>
                                    {subcontractorItems
                                        ? formatPrice(
                                              sumPropValues(subcontractorItems, 'totalPrice')
                                          )
                                        : '0,00'}
                                </strong>
                            </Table.Summary.Cell>
                        );
                    })}

                    <Table.Summary.Cell index={9}></Table.Summary.Cell>
                </Table.Summary.Row>
            </Table.Summary>
        );
    };

    public render(): React.ReactElement {
        const {
            activeContractor,
            activeSubcontractors,
            projectItems,
            projectContractorItems,
            filter,
            showAllItems,
            onAdd,
            onEdit,
            onSave,
            onFilterChange,
        } = this.props;

        if (!projectItems || !projectContractorItems) {
            return (
                <Table
                    locale={getTableLocale()}
                    key="loading-table"
                    loading
                    pagination={false}
                    size="small"
                    className="ant-table-slim"
                    columns={this.getTableColumns()}
                />
            );
        }

        const mergedItems = connectContractorItems(
            projectItems,
            projectContractorItems,
            activeContractor.id,
            activeSubcontractors,
            !showAllItems
        );

        const filteredItems = filter
            ? recursiveFilterAndSort(mergedItems, filter).pageItems
            : mergedItems;

        return (
            <Form onFinish={onSave} preserve={false}>
                <Table
                    locale={getTableLocale()}
                    key="data-table"
                    pagination={false}
                    size="small"
                    className="ant-table-slim"
                    scroll={{ y: `calc(100vh - ${NON_TABLE_CONTENT_HEIGHT}px)` }}
                    rowKey={(record: ProjectItemExtendedVm) => record.id}
                    onRow={(record: ProjectItemExtendedVm) => {
                        return {
                            onDoubleClick: () =>
                                record.contractorItem
                                    ? onEdit(record.contractorItem)
                                    : onAdd(record.id, activeContractor.id),
                        };
                    }}
                    dataSource={filteredItems}
                    columns={this.getTableColumns()}
                    onChange={(_: any, filters: any) => onFilterChange(filters)}
                    expandable={this.getTableExpandableConfig()}
                    summary={(data: readonly ProjectItemExtendedVm[]): React.ReactNode =>
                        this.getTableSummary(data)
                    }
                />
            </Form>
        );
    }
}

export default ProjectContractorItemTable;
