import { LoginOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { translations } from '../../config/translations';
import { AuthorizationRoutes } from './ApiAuthorizationConstants';
import authService from './AuthorizeService';

interface Props {}

interface State {
    isAuthenticated: boolean;
    userName?: string;
}

export class LoginMenu extends React.Component<Props, State> {
    _subscription: any;

    constructor(props: Props) {
        super(props);

        this.state = {
            isAuthenticated: false,
        };
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    async populateState() {
        const [isAuthenticated, user] = await Promise.all([
            authService.isAuthenticated(),
            authService.getUserProfile(),
        ]);
        this.setState({
            isAuthenticated,
            userName: user?.name,
        });
    }

    render() {
        const { isAuthenticated } = this.state;

        return (
            <Menu
                theme="dark"
                mode="inline"
                defaultSelectedKeys={['1']}
                className={isAuthenticated ? 'bottom-menu' : ''}
                items={
                    isAuthenticated
                        ? [
                              {
                                  key: 'profile',
                                  label: (
                                      <Link to={AuthorizationRoutes.Profile}>
                                          {translations.account.title}
                                      </Link>
                                  ),
                                  icon: <UserOutlined />,
                              },
                              {
                                  key: 'logout',
                                  label: (
                                      <Link
                                          to={{
                                              pathname: `${AuthorizationRoutes.LogOut}`,
                                              state: {
                                                  local: true,
                                                  returnUrl:
                                                      AuthorizationRoutes.DefaultLoginRedirectPath,
                                              },
                                          }}
                                      >
                                          {translations.account.logout}
                                      </Link>
                                  ),
                                  icon: <LogoutOutlined />,
                              },
                          ]
                        : [
                              {
                                  key: 'login',
                                  label: (
                                      <Link to={AuthorizationRoutes.Login}>
                                          {translations.account.login}
                                      </Link>
                                  ),
                                  icon: <LoginOutlined />,
                              },
                          ]
                }
            />
        );
    }
}
