import { Props } from '.';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Table, { ColumnProps, TablePaginationConfig } from 'antd/lib/table';
import { EmployeeExpenseEmployeeVm, EmployeeExpenseVm } from '../../../utils/api';
import {
    applySearchParams,
    getFiltersParams,
    getPagingSortingParams,
} from '../../../helpers/SearchParamsHelper';
import { translations } from '../../../config/translations';
import { getSearchFilter } from '../../../helpers/FilterHelper';
import Routes from '../../../config/routes';
import ReplaceStrings from '../../../config/replaceStrings';
import { getTableLocale } from '../../../helpers/TableHelper';
import { defaultTablePagination } from '../../../config/constants';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { formatPrice } from '../../../helpers/PriceHelper';

const EmployeeExpensesTable: React.FC<Props> = (props: Props) => {
    const history = useHistory();
    const location = useLocation();

    const getTableColumns = (): ColumnProps<EmployeeExpenseEmployeeVm>[] => {
        const params = new URLSearchParams(location.search);
        const { sortByParam, sortOrderParam } = getPagingSortingParams(params);
        const filters = getFiltersParams(params);

        const dynamicColumns = props.projectColumns.map((col) => ({
            title: col.projectName,
            dataIndex: col.projectId.toString(),
            key: col.projectId,
            align: 'right',
            render: (text: string, record: EmployeeExpenseEmployeeVm) => {
                const expense = record.employeeExpense?.find(
                    (expense: EmployeeExpenseVm) => expense.projectId === col.projectId
                );
                if (record.employeeExpense?.length === 0) {
                    return <></>;
                }
                return <span>{expense ? formatPrice(expense.amount) : '0,00'}</span>;
            },
        }));

        return [
            {
                title: translations.employeeExpenses.employee,
                dataIndex: ['employee', 'fullName'],
                ...getSearchFilter(),
                sorter: true,
                filteredValue: filters.Employee_Name ? [filters.Employee_Name] : null,
                sortOrder: sortByParam === 'Employee_Name' ? sortOrderParam : null,
                width: 250,
                render: (text: string, record: EmployeeExpenseEmployeeVm): React.ReactElement => (
                    <Link
                        to={Routes.ROUTE_EMPLOYEES_READ.replace(
                            ReplaceStrings.ENTITY_ID,
                            record.employee?.id?.toString() || '0'
                        )}
                    >
                        {text}
                    </Link>
                ),
            },
            ...dynamicColumns,
            {
                title: <b>{translations.employeeExpenses.total}</b>,
                align: 'right',
                render: (text: string, record: EmployeeExpenseEmployeeVm): React.ReactElement => (
                    <b>{formatPrice(getTotalExpense(record.employeeExpense ?? []))}</b>
                ),
            },
        ];
    };

    const getProjectTotalExpenses = (): { [key: string]: number } => {
        const totals: { [key: string]: number } = {};
        props.employeeExpensesEmployee.forEach((record) => {
            record.employeeExpense?.forEach((expense) => {
                if (expense && expense.projectId !== undefined) {
                    const key = expense.projectId.toString();
                    totals[key] = (totals[key] || 0) + expense.amount;
                }
            });
        });
        return totals;
    };

    const projectTotalExpenses = getProjectTotalExpenses();

    const getTotalExpense = (expenses: EmployeeExpenseVm[]): number => {
        return expenses.reduce((total, expense) => total + expense.amount, 0);
    };

    const calculateOverallTotal = (): number => {
        return Object.values(projectTotalExpenses).reduce((sum, amount) => sum + amount, 0);
    };

    const createTableSearchParams = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null> = {},
        sorter:
            | SorterResult<EmployeeExpenseEmployeeVm>
            | SorterResult<EmployeeExpenseEmployeeVm>[] = {}
    ): URLSearchParams => {
        const tableSearchParams = new URLSearchParams();

        // Handle pagination
        tableSearchParams.append('pageIndex', pagination.current?.toString() || '1');
        tableSearchParams.append(
            'pageSize',
            pagination.pageSize?.toString() || pagination.defaultPageSize?.toString() || ''
        );

        // Handle sorting
        const singleSorter = sorter as SorterResult<EmployeeExpenseEmployeeVm>;
        let sortBy;
        if (
            Array.isArray(singleSorter.field) &&
            singleSorter.field[0] === 'employee' &&
            singleSorter.field[1] === 'fullName'
        ) {
            sortBy = 'Employee_Name';
        } else {
            sortBy = singleSorter.field?.toString() || undefined;
        }

        if (sortBy && singleSorter.order) {
            tableSearchParams.set('sortBy', sortBy);
            tableSearchParams.set('sortOrder', singleSorter.order);
        } else {
            tableSearchParams.delete('sortBy');
            tableSearchParams.delete('sortOrder');
        }

        // Handle filtering
        const employeeFullNameFilter = filters['employee.fullName'];
        if (employeeFullNameFilter) {
            const filterValue = employeeFullNameFilter.toString();
            tableSearchParams.set('filter.Employee_Name', filterValue || '');
        }

        // Append the selected company, month, and year to the URL
        tableSearchParams.set('companyId', props.selectedCompanyId?.toString() || '');
        tableSearchParams.set('month', props.selectedMonth?.toString() || '');
        tableSearchParams.set('year', props.selectedYear?.toString() || '');

        return tableSearchParams;
    };

    const handleTableChange = async (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null> = {},
        sorter:
            | SorterResult<EmployeeExpenseEmployeeVm>
            | SorterResult<EmployeeExpenseEmployeeVm>[] = {}
    ) => {
        const currSearchParams = new URLSearchParams(location.search);
        const tableSearchParams = createTableSearchParams(pagination, filters, sorter);
        const newSearchParams = applySearchParams(currSearchParams, tableSearchParams);

        history.push({
            pathname: history.location.pathname,
            search: newSearchParams.toString(),
        });
    };

    const params = new URLSearchParams(location.search);
    const { pageIndexParam, pageSizeParam } = getPagingSortingParams(params);
    const pagination: TablePaginationConfig = {
        ...defaultTablePagination,
        current:
            pageIndexParam && parseInt(pageIndexParam)
                ? parseInt(pageIndexParam)
                : defaultTablePagination.defaultCurrent,
        pageSize:
            pageSizeParam && parseInt(pageSizeParam)
                ? parseInt(pageSizeParam)
                : defaultTablePagination.defaultPageSize,
        total: props.totalCount,
    };

    return (
        <Table
            locale={getTableLocale()}
            columns={getTableColumns()}
            className="ant-table-slim"
            dataSource={props.employeeExpensesEmployee}
            loading={props.loading}
            rowKey={(record: EmployeeExpenseEmployeeVm): string =>
                record.employee?.id?.toString() || '0'
            }
            onChange={handleTableChange}
            pagination={pagination}
            bordered
            size="small"
            summary={() => (
                <Table.Summary.Row>
                    <Table.Summary.Cell index={0}>
                        <b>{translations.employeeExpenses.total.toUpperCase()}</b>
                    </Table.Summary.Cell>
                    {props.projectColumns.map((col, index) => (
                        <Table.Summary.Cell index={index + 1} align="right" key={col.projectId}>
                            <b>
                                {formatPrice(projectTotalExpenses[col.projectId.toString()] || 0)}
                            </b>
                        </Table.Summary.Cell>
                    ))}
                    <Table.Summary.Cell index={props.projectColumns.length + 1} align="right">
                        <b>{formatPrice(calculateOverallTotal())}</b>
                    </Table.Summary.Cell>
                </Table.Summary.Row>
            )}
        />
    );
};

export default EmployeeExpensesTable;
