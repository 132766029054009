import { FormProps } from 'antd/lib/form';
import {
    DocumentDetailVm,
    DocumentItemVm,
    DocumentTypeEnum,
    WarehouseItemVm,
} from '../../../utils/api';
import DocumentForm from './DocumentForm';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { RouteParamsGeneric } from '../../../core/models/RouteParams';

interface OwnProps {
    document?: DocumentDetailVm;
    typeId: DocumentTypeEnum;
    documentItems?: DocumentItemVm[];
    warehouseItems?: WarehouseItemVm[];
    onClose: () => void;
    onSuccess: (document: DocumentDetailVm) => void;
}

export type Props = OwnProps & FormProps & RouteComponentProps<RouteParamsGeneric>;

export default withRouter(DocumentForm);
