import {
    CheckOutlined,
    CloseOutlined,
    DeleteOutlined,
    EditOutlined,
    EllipsisOutlined,
    FolderAddOutlined,
    PlusOutlined,
} from '@ant-design/icons';
import { Button, Dropdown, MenuProps, Space } from 'antd';
import { translations } from '../../config/translations';
import { showConfirm } from '../../helpers/NotificationHelper';
import { Props } from './index';

const ProjectItemActions = (props: Props) => {
    const {
        item,
        editingItem,
        allowCreate,
        allowDelete,
        allowUpdate,
        onCancel,
        onAdd,
        onEdit,
        onDelete,
    } = props;

    if (!(allowCreate || allowDelete || allowUpdate)) {
        return <div />;
    }

    let items: MenuProps['items'] = [];

    if (item.isGroup && allowCreate) {
        items.push({
            key: '1',
            onClick: (e) => {
                e.domEvent.stopPropagation();
                onAdd(false, item.id);
            },
            icon: <PlusOutlined />,
            label: translations.projects.addItem,
        });
        items.push({
            key: '2',
            onClick: (e) => {
                e.domEvent.stopPropagation();
                onAdd(true, item.id);
            },
            icon: <FolderAddOutlined />,
            label: translations.projects.addGroup,
        });
    }

    if (allowUpdate) {
        items.push({
            key: '3',
            onClick: (e) => {
                e.domEvent.stopPropagation();
                onEdit(item);
            },
            icon: <EditOutlined />,
            label: translations.projects.editItem,
        });
    }
    if (allowDelete) {
        items.push({
            key: '4',
            onClick: (e) => {
                e.domEvent.stopPropagation();
                showConfirm(
                    () => onDelete(item.id),
                    translations.projects.deleteItemConfirm,
                    translations.general.delete,
                    true
                );
            },
            icon: <DeleteOutlined />,
            label: translations.projects.deleteItem,
            danger: true,
        });
    }

    return editingItem?.id === item.id ? (
        <Space>
            <Button
                shape="circle"
                type="primary"
                size="small"
                htmlType="submit"
                onClick={(e) => e.stopPropagation()}
                icon={<CheckOutlined />}
            />
            <Button
                shape="circle"
                size="small"
                onClick={(e) => {
                    e.stopPropagation();
                    onCancel();
                }}
                icon={<CloseOutlined />}
            />
        </Space>
    ) : (
        <Dropdown trigger={['click']} menu={{ items }}>
            <Button
                shape="circle"
                type="text"
                size="small"
                onClick={(e) => e.stopPropagation()}
                icon={<EllipsisOutlined />}
            />
        </Dropdown>
    );
};

export default ProjectItemActions;
