import {
    DeleteOutlined,
    DownOutlined,
    EditOutlined,
    FileDoneOutlined,
    FileExcelOutlined,
    FileExclamationOutlined,
    FilePdfOutlined,
    PrinterOutlined,
} from '@ant-design/icons';
import { Col, Dropdown, Form, Input, Row, Space } from 'antd';
import Button from 'antd-button-color';
import React from 'react';
import { Link } from 'react-router-dom';
import { formItemLayout724 } from '../../../../config/formLayouts';
import ReplaceStrings from '../../../../config/replaceStrings';
import Routes from '../../../../config/routes';
import { translations } from '../../../../config/translations';
import { ActionType, DrawerState, ModuleName } from '../../../../core/models/enum';
import { authorizeAction } from '../../../../helpers/CheckPermissionHelper';
import { formatDate } from '../../../../helpers/DateHelper';
import {
    getDocumentStatusBadge,
    isCompletableDocumentType,
    isProcessableDocumentType,
    isStornableDocumentType,
} from '../../../../helpers/DocumentHelper';
import { downloadFile } from '../../../../helpers/FileHelper';
import { showConfirm, showError, showSuccess } from '../../../../helpers/NotificationHelper';
import { formatPrice } from '../../../../helpers/PriceHelper';
import { buildRoute } from '../../../../helpers/RoutingHelper';
import { remove } from '../../../../helpers/SubmitHelper';
import {
    ApiException,
    CompleteDocumentCommand,
    CopyDocumentCommand,
    DocumentsClient,
    DocumentStatusEnum,
    DocumentTypeEnum,
    FileResponse,
    ExportOrderPDFCommand,
    ProcessDocumentCommand,
    ExportOrderXLSXCommand,
    RoleEnum,
} from '../../../../utils/api';
import { Props } from './index';

const { TextArea } = Input;

interface State {
    allowUpdate: boolean;
    allowDelete: boolean;
}

enum ExportTypeEnum {
    PDF,
    XLSX,
}

class DocumentDetails extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        const { userProfile } = props;

        this.state = {
            allowUpdate: authorizeAction(userProfile, ModuleName.Documents, ActionType.Update),
            allowDelete: authorizeAction(userProfile, ModuleName.Documents, ActionType.Delete),
        };
    }

    private handleDocumentEdit = () => {
        const {
            history,
            location: { search },
            document,
        } = this.props;

        history.push({
            pathname: buildRoute(
                Routes.ROUTE_DOCUMENTS,
                Routes.ROUTE_DOCUMENTS_NEW,
                Routes.ROUTE_DOCUMENTS_READ,
                Routes.ROUTE_DOCUMENTS_EDIT,
                DrawerState.Edit,
                document.id.toString()
            ),
            search,
        });
    };

    private handleDocumentDeleted = () => {
        const {
            history,
            location: { search },
        } = this.props;

        history.push({
            pathname: Routes.ROUTE_DOCUMENTS,
            search,
        });
    };

    private handleDocumentComplete = async () => {
        const { document, docTypeTranslations, onComplete } = this.props;

        try {
            const result = await new DocumentsClient().complete(
                new CompleteDocumentCommand({ documentId: document.id })
            );

            showSuccess(docTypeTranslations.completeSuccess);

            onComplete(result);
        } catch (error) {
            if (error instanceof ApiException) {
                showError(error.response);
            } else {
                showError(translations.general.errorSavingData);
            }
        }
    };

    private handleDocumentProcess = async () => {
        const { document, onComplete } = this.props;

        try {
            const result = await new DocumentsClient().process(
                new ProcessDocumentCommand({ documentId: document.id })
            );

            showSuccess(translations.documents.sendToProcessingSuccess);

            onComplete(result);
        } catch (error) {
            if (error instanceof ApiException) {
                showError(error.response);
            } else {
                showError(translations.general.errorSavingData);
            }
        }
    };

    private handleDocumentStorno = async () => {
        const {
            document,
            history,
            location: { search },
            docTypeTranslations,
        } = this.props;

        const result = await new DocumentsClient().copy(
            new CopyDocumentCommand({
                sourceDocumentId: document.id,
                destTypeId: document.documentTypeId,
                isStorno: true,
            })
        );

        showSuccess(docTypeTranslations.generateSuccess);

        history.push({
            pathname: buildRoute(
                Routes.ROUTE_DOCUMENTS,
                Routes.ROUTE_DOCUMENTS_NEW,
                Routes.ROUTE_DOCUMENTS_READ,
                Routes.ROUTE_DOCUMENTS_EDIT,
                DrawerState.Closed,
                result.id.toString()
            ),
            search,
        });
    };

    private handleDocumentExport = async (exportType: ExportTypeEnum) => {
        const { document } = this.props;

        try {
            if (exportType === ExportTypeEnum.PDF) {
                const result = await new DocumentsClient().exportPDF(
                    ExportOrderPDFCommand.fromJS({
                        id: document.id,
                    })
                );
                this.handleExportSuccess(result);
            }
            if (exportType === ExportTypeEnum.XLSX) {
                const result = await new DocumentsClient().exportXLSX(
                    ExportOrderXLSXCommand.fromJS({
                        id: document.id,
                    })
                );
                this.handleExportSuccess(result);
            }
        } catch (error) {
            if (error instanceof ApiException) {
                showError(error.response);
            } else {
                showError(translations.general.errorSavingData);
            }
        }
    };

    private handleExportSuccess = (result: FileResponse) => {
        showSuccess(translations.documents.exportSuccess);

        downloadFile(result);
    };

    private getDocumentTypeSpecificFields = (): React.ReactElement => {
        const { document } = this.props;

        const destinationProject = (
            <Form.Item label={translations.documents.destinationProject}>
                <Input readOnly value={document.destinationProject} />
            </Form.Item>
        );

        const receiptCode = (
            <Form.Item label={translations.documents.receiptCode}>
                <Input readOnly value={document.receiptCode} />
            </Form.Item>
        );

        const receiptAmount = (
            <Form.Item label={translations.documents.receiptAmount}>
                <Input
                    readOnly
                    value={document.receiptAmount ? formatPrice(document.receiptAmount) : undefined}
                />
            </Form.Item>
        );

        const deadlineDate = (
            <Form.Item label={translations.documents.deadlineDate}>
                <Input readOnly value={formatDate(document.deadlineDate)} />
            </Form.Item>
        );

        const supplier = (
            <Form.Item label={translations.documents.supplier}>
                <Input readOnly value={document.supplier} />
            </Form.Item>
        );

        const parentCode = (
            <Form.Item label={translations.documents.parentDocument}>
                {document.parentId ? (
                    <Link
                        to={{
                            pathname: Routes.ROUTE_DOCUMENTS_READ.replace(
                                ReplaceStrings.ENTITY_ID,
                                document.parentId.toString()
                            ),
                            search: `?typeId=${document.parentTypeId}`,
                        }}
                    >
                        {document.parentCode}
                    </Link>
                ) : (
                    <Input readOnly />
                )}
            </Form.Item>
        );

        const stornedDocumentCode = (
            <Form.Item label={translations.documents.stornedDocumentCode}>
                {document.stornedDocumentId ? (
                    <Link
                        to={{
                            pathname: Routes.ROUTE_DOCUMENTS_READ.replace(
                                ReplaceStrings.ENTITY_ID,
                                document.stornedDocumentId.toString()
                            ),
                            search: `?typeId=${document.stornedDocumentTypeId}`,
                        }}
                    >
                        {document.stornedDocumentCode}
                    </Link>
                ) : (
                    <Input readOnly />
                )}
            </Form.Item>
        );

        switch (document.documentTypeId) {
            case DocumentTypeEnum.RequisitionOrder:
                return (
                    <>
                        {supplier}
                        {deadlineDate}
                    </>
                );
            case DocumentTypeEnum.PurchaseOrder:
                return (
                    <>
                        {parentCode}
                        {supplier}
                        {deadlineDate}
                    </>
                );
            case DocumentTypeEnum.PurchaseReceivedNote:
                return (
                    <>
                        {parentCode}
                        {supplier}
                        {stornedDocumentCode}
                    </>
                );
            case DocumentTypeEnum.TransferNote:
                return (
                    <>
                        {parentCode}
                        {destinationProject}
                        {deadlineDate}
                        {stornedDocumentCode}
                    </>
                );
            case DocumentTypeEnum.TransferReceivedNote:
                return (
                    <>
                        {parentCode}
                        {destinationProject}
                        {stornedDocumentCode}
                    </>
                );
            case DocumentTypeEnum.ConsumptionNote:
                return <>{stornedDocumentCode}</>;
            case DocumentTypeEnum.WriteOffNote:
                return (
                    <>
                        {parentCode}
                        {stornedDocumentCode}
                    </>
                );
            case DocumentTypeEnum.Liquidation:
                return (
                    <>
                        {parentCode}
                        {supplier}
                        {receiptCode}
                        {receiptAmount}
                    </>
                );
            case DocumentTypeEnum.Order:
                return (
                    <>
                        {parentCode}
                        {supplier}
                        {deadlineDate}
                    </>
                );
            default:
                return <></>;
        }
    };

    public render(): React.ReactElement {
        const { document, docTypeTranslations, userProfile } = this.props;
        const { allowDelete, allowUpdate } = this.state;

        const isSiteManager = Number(userProfile['userRoleId']) === RoleEnum.SiteManager;

        const isDraft = document.documentStatusId === DocumentStatusEnum.Draft;
        const isProcessing = document.documentStatusId === DocumentStatusEnum.Processing;
        const isCompleted = document.documentStatusId === DocumentStatusEnum.Completed;

        const isProcessable = isProcessableDocumentType(document.documentTypeId);
        const isStornableType = isStornableDocumentType(document.documentTypeId);
        const isCompletableType = isCompletableDocumentType(document.documentTypeId);

        const canEditDocument = allowUpdate && !isCompleted && (isDraft || !isSiteManager);
        const canDeleteDocument = allowDelete && !isCompleted && (isDraft || !isSiteManager);

        const canSendToProcessing = allowUpdate && isDraft && isProcessable;
        const canCompleteDocument =
            allowUpdate && isCompletableType && (isProcessing || (isDraft && !isProcessable));

        const isStornoDocument = !!document.stornedDocumentId;
        const canStornoDocument =
            allowUpdate && isCompleted && isStornableType && !isStornoDocument;

        return (
            <>
                <Row>
                    <Col span={3} offset={21}>
                        <Space style={{ float: 'right', marginBottom: 15 }}>
                            {canStornoDocument && (
                                <Button
                                    danger
                                    className="no-print"
                                    icon={<FileExclamationOutlined />}
                                    onClick={() =>
                                        showConfirm(
                                            () => this.handleDocumentStorno(),
                                            docTypeTranslations.stornoConfirm,
                                            translations.general.storno,
                                            true
                                        )
                                    }
                                >
                                    {translations.general.storno}
                                </Button>
                            )}

                            {canSendToProcessing && (
                                <Button
                                    type="primary"
                                    ghost
                                    className="no-print"
                                    icon={<FileDoneOutlined />}
                                    onClick={() =>
                                        showConfirm(
                                            () => this.handleDocumentProcess(),
                                            translations.documents.sendToProcessingConfirm,
                                            translations.general.confirm
                                        )
                                    }
                                >
                                    {translations.documents.sendToProcessing}
                                </Button>
                            )}

                            {canCompleteDocument && (
                                <Button
                                    type="primary"
                                    ghost
                                    className="no-print"
                                    icon={<FileDoneOutlined />}
                                    onClick={() =>
                                        showConfirm(
                                            () => this.handleDocumentComplete(),
                                            docTypeTranslations.completeConfirm,
                                            translations.documents.complete
                                        )
                                    }
                                >
                                    {translations.documents.complete}
                                </Button>
                            )}

                            {canEditDocument && (
                                <Button
                                    type="primary"
                                    className="no-print"
                                    icon={<EditOutlined />}
                                    onClick={this.handleDocumentEdit}
                                >
                                    {translations.general.edit}
                                </Button>
                            )}

                            {canDeleteDocument && (
                                <Button
                                    danger
                                    className="no-print"
                                    icon={<DeleteOutlined />}
                                    onClick={() =>
                                        showConfirm(
                                            () =>
                                                remove(
                                                    new DocumentsClient(),
                                                    docTypeTranslations.deleteSuccess,
                                                    () => this.handleDocumentDeleted(),
                                                    document.id
                                                ),
                                            docTypeTranslations.deleteConfirm,
                                            translations.general.delete,
                                            true
                                        )
                                    }
                                >
                                    {translations.general.delete}
                                </Button>
                            )}

                            <Dropdown
                                className="no-print"
                                trigger={['click']}
                                menu={{
                                    items: [
                                        {
                                            key: 'printPdf',
                                            onClick: (e) => {
                                                e.domEvent.stopPropagation();
                                                window.print();
                                            },
                                            icon: <PrinterOutlined />,
                                            label: translations.general.printPdf,
                                        },
                                    ],
                                }}
                            >
                                <Button
                                    key="print"
                                    style={{ zIndex: 10 }}
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    <DownOutlined />
                                    {translations.general.print}
                                </Button>
                            </Dropdown>

                            {document.documentTypeId === DocumentTypeEnum.Order && (
                                <Dropdown
                                    className="no-print"
                                    trigger={['click']}
                                    menu={{
                                        items: [
                                            {
                                                key: 'exportPdf',
                                                onClick: (e) => {
                                                    e.domEvent.stopPropagation();
                                                    this.handleDocumentExport(ExportTypeEnum.PDF);
                                                },
                                                icon: <FilePdfOutlined />,
                                                label: translations.general.exportPdf,
                                            },
                                            {
                                                key: 'exportXlsx',
                                                onClick: (e) => {
                                                    e.domEvent.stopPropagation();
                                                    this.handleDocumentExport(ExportTypeEnum.XLSX);
                                                },
                                                icon: <FileExcelOutlined />,
                                                label: translations.general.exportXlsx,
                                            },
                                        ],
                                    }}
                                >
                                    <Button
                                        key="export"
                                        style={{ zIndex: 10 }}
                                        onClick={(e) => e.stopPropagation()}
                                    >
                                        <DownOutlined />
                                        {translations.general.export}
                                    </Button>
                                </Dropdown>
                            )}
                        </Space>
                    </Col>

                    <Col span={12}>
                        <Form size="small" {...formItemLayout724}>
                            <Form.Item label={translations.documents.code}>
                                <Input readOnly value={document.code} />
                            </Form.Item>

                            <Form.Item label={translations.documents.project}>
                                <Input readOnly value={document.project} />
                            </Form.Item>

                            {this.getDocumentTypeSpecificFields()}

                            <Form.Item label={translations.documents.comment}>
                                <TextArea autoSize readOnly value={document.comment} />
                            </Form.Item>
                        </Form>
                    </Col>
                    <Col span={12}>
                        <Form size="small" {...formItemLayout724}>
                            <Form.Item label={translations.documents.status}>
                                {getDocumentStatusBadge(
                                    document.documentStatusId,
                                    document.documentStatus
                                )}
                            </Form.Item>

                            <Form.Item label={translations.documents.createdBy}>
                                <Input readOnly value={document.createdBy} />
                            </Form.Item>

                            <Form.Item label={translations.documents.createdDate}>
                                <Input readOnly value={formatDate(document.createdDate)} />
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </>
        );
    }
}

export default DocumentDetails;
