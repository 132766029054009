import {
    CheckOutlined,
    CloseOutlined,
    DeleteOutlined,
    EditOutlined,
    EllipsisOutlined,
    PlusOutlined,
} from '@ant-design/icons';
import { Button, Dropdown, MenuProps } from 'antd';
import { Props } from '.';
import { translations } from '../../../config/translations';
import { showConfirm } from '../../../helpers/NotificationHelper';

const HodogramActions = (props: Props) => {
    const {
        item,
        allowCreate,
        allowDelete,
        allowUpdate,
        onAdd,
        onEdit,
        onDelete,
        onActivate,
        onDeactivate,
    } = props;

    if (!(allowCreate || allowDelete || allowUpdate)) {
        return <div />;
    }

    let items: MenuProps['items'] = [];

    if (allowCreate) {
        items.push({
            key: '1',
            onClick: (e) => {
                e.domEvent.stopPropagation();
                onAdd(item.id);
            },
            icon: <PlusOutlined />,
            label: translations.hodogram.addHodogram,
        });
    }

    if (allowUpdate) {
        if (item.isActive) {
            items.push({
                key: '2',
                onClick: (e) => {
                    e.domEvent.stopPropagation();
                    onDeactivate(item);
                },
                icon: <CloseOutlined />,
                label: translations.hodogram.deactivateHodogram,
            });
        } else {
            items.push({
                key: '3',
                onClick: (e) => {
                    e.domEvent.stopPropagation();
                    onActivate(item);
                },
                icon: <CheckOutlined />,
                label: translations.hodogram.activateHodogram,
            });
        }

        items.push({
            key: '4',
            onClick: (e) => {
                e.domEvent.stopPropagation();
                onEdit(item);
            },
            icon: <EditOutlined />,
            label: translations.hodogram.editHodogram,
        });
    }

    if (allowDelete) {
        items.push({
            key: '5',
            onClick: (e) => {
                e.domEvent.stopPropagation();
                showConfirm(
                    () => onDelete(item),
                    translations.hodogram.deleteMessage,
                    translations.general.delete,
                    true
                );
            },
            icon: <DeleteOutlined />,
            danger: true,
            label: translations.hodogram.deleteHodogram,
        });
    }

    return (
        <Dropdown trigger={['click']} menu={{ items }}>
            <Button
                shape="circle"
                type="text"
                size="small"
                onClick={(e) => e.stopPropagation()}
                icon={<EllipsisOutlined />}
            />
        </Dropdown>
    );
};

export default HodogramActions;
