import { DatePicker, Form, Input, Select } from 'antd';
import moment from 'moment';
import React from 'react';
import DrawerButtons from '../../../components/drawer-buttons';
import InputPrice from '../../../components/input-price';
import { defaultFormat } from '../../../config/constants';
import { formItemLayout724 } from '../../../config/formLayouts';
import { translations } from '../../../config/translations';
import { getDocumentTypeTranslations } from '../../../helpers/DocumentHelper';
import { showError, showSuccess } from '../../../helpers/NotificationHelper';
import {
    ApiException,
    AssociateTypeEnum,
    CooperatorsClient,
    CooperatorVm,
    CreateDocumentCommandOfDocument,
    DocumentDetailVm,
    DocumentItemVm,
    DocumentsClient,
    DocumentTypeEnum,
    ProjectsClient,
    ProjectStatusEnum,
    ProjectVm,
    RoleEnum,
    UpdateDocumentCommandOfDocument,
    WarehouseItemVm,
} from '../../../utils/api';
import DocumentItemTable from '../document-item-table';
import { Props } from './index';
import authService from '../../../components/api-authorization/AuthorizeService';

interface State {
    projects: ProjectVm[];
    suppliers: CooperatorVm[];
    isSaving?: boolean;
    userProfile: any;
    projectId?: number;
}

const { TextArea } = Input;

class DocumentForm extends React.Component<Props, State> {
    private formRef: any;

    public constructor(props: Props) {
        super(props);

        this.formRef = React.createRef();

        this.state = {
            projects: [],
            suppliers: [],
            userProfile: this.getUserProfile()
        };
    }

    public componentDidMount = () => {
        this.getProjects();
        this.getSuppliers();
    };

    private async getUserProfile() {
        this.setState({ userProfile: await authService.getUserProfile() });
    }

    private getProjects = async () => {
        const { location } = this.props;

        this.setState({
            projects: await new ProjectsClient().getAll(),  
        }, () => {
            const projectId = this.state.projects.find(p => p.warehouseId === Number(new URLSearchParams(location.search).get('projectId')!))?.id;

            this.formRef?.current?.setFieldsValue({
                'projectId': projectId
            });
            
            this.setState({projectId: projectId})
        });
    };

    private getSuppliers = async () => {
        this.setState({
            suppliers: await new CooperatorsClient().getByAssociateType(AssociateTypeEnum.Supplier),
        });
    };

    private handleSubmit = async (values: any) => {
        const { documentItems, warehouseItems } = this.props;

        this.setState({
            isSaving: true,
        });

        let result;

        try {
            if (values.id) {
                result = await new DocumentsClient().update(
                    values.id,
                    UpdateDocumentCommandOfDocument.fromJS(values)
                );
            } else {
                result = await new DocumentsClient().create(
                    CreateDocumentCommandOfDocument.fromJS({
                        ...values,
                        documentItemIds: documentItems?.map((item: DocumentItemVm) => item.id),
                        warehouseItemIds: warehouseItems?.map((item: WarehouseItemVm) => item.id),
                    })
                );
            }

            this.handleSuccess(result);
        } catch (error) {
            if (error instanceof ApiException) {
                showError(error.response);
            } else {
                showError(translations.general.errorSavingData);
            }
        }

        this.setState({
            isSaving: false,
        });
    };

    private handleSuccess = (document: DocumentDetailVm) => {
        const { onSuccess } = this.props;

        const docTypeTranslations = getDocumentTypeTranslations(document.documentTypeId);

        showSuccess(docTypeTranslations.saveSuccess);

        onSuccess(document);
    };

    private getDocumentTypeFormItems = (): React.ReactElement => {
        const { document, typeId } = this.props;
        const { projects, suppliers } = this.state;

        const destinationProject = (
            <Form.Item
                name="destinationProjectId"
                label={translations.documents.destinationProject}
                initialValue={document?.destinationProjectId}
            >
                <Select showSearch allowClear optionFilterProp="children">
                    {projects.map((p: ProjectVm) => (
                        <Select.Option key={p.id} value={p.id}>
                            {p.name}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
        );

        const receiptCode = (
            <Form.Item
                name="receiptCode"
                label={translations.documents.receiptCode}
                initialValue={document?.receiptCode}
                rules={[{ max: 200, message: translations.general.maxLength200 }]}
            >
                <Input />
            </Form.Item>
        );

        const receiptAmount = (
            <Form.Item
                name="receiptAmount"
                label={translations.documents.receiptAmount}
                initialValue={document?.receiptAmount}
            >
                <InputPrice />
            </Form.Item>
        );

        const deadlineDate = (
            <Form.Item
                name="deadlineDate"
                label={translations.documents.deadlineDate}
                initialValue={document?.deadlineDate ? moment(document.deadlineDate) : null}
            >
                <DatePicker
                    placeholder={translations.general.chooseDate}
                    format={defaultFormat}
                    style={{ width: '100%' }}
                    allowClear
                />
            </Form.Item>
        );

        const supplier = (
            <Form.Item
                name="supplierId"
                label={translations.documents.supplier}
                initialValue={document?.supplierId}
                rules={[
                    {
                        required: typeId === DocumentTypeEnum.Order,
                        message: translations.general.requiredField,
                    },
                ]}
            >
                <Select showSearch allowClear optionFilterProp="children">
                    {suppliers.map((p: CooperatorVm) => (
                        <Select.Option key={p.id} value={p.id}>
                            {p.name}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
        );

        switch (typeId) {
            case DocumentTypeEnum.RequisitionOrder:
                return (
                    <>
                        {supplier}
                        {deadlineDate}
                    </>
                );
            case DocumentTypeEnum.PurchaseOrder:
                return (
                    <>
                        {supplier}
                        {deadlineDate}
                    </>
                );
            case DocumentTypeEnum.PurchaseReceivedNote:
                return <>{supplier}</>;
            case DocumentTypeEnum.TransferNote:
                return (
                    <>
                        {destinationProject}
                        {deadlineDate}
                    </>
                );
            case DocumentTypeEnum.TransferReceivedNote:
                return <>{destinationProject}</>;
            case DocumentTypeEnum.ConsumptionNote:
                return <></>;
            case DocumentTypeEnum.WriteOffNote:
                return <></>;
            case DocumentTypeEnum.Liquidation:
                return (
                    <>
                        {supplier}
                        {receiptCode}
                        {receiptAmount}
                    </>
                );
            case DocumentTypeEnum.Order:
                return (
                    <>
                        {supplier}
                        {deadlineDate}
                    </>
                );
            default:
                return <></>;
        }
    };

    public render(): React.ReactElement {
        const { typeId, document, documentItems, warehouseItems, onClose } = this.props;
        const { isSaving, projects, userProfile, projectId } = this.state;
        const role = Number(userProfile['userRoleId']);
        
        return (
            <Form ref={this.formRef} onFinish={this.handleSubmit} {...formItemLayout724} >
                <DrawerButtons isSaving={isSaving} onCancelAction={onClose} />

                <Form.Item
                    name="projectId"
                    label={translations.documents.project}
                    rules={[
                        {
                            required: role === RoleEnum.SiteManager,
                            message: translations.general.requiredField,
                        },
                    ]}
                >
                    <Select showSearch allowClear optionFilterProp="children">
                        {projects
                            .filter((p: ProjectVm) =>
                                p.projectStatusId === ProjectStatusEnum.Active ||
                                p.id === projectId
                            )
                            .map((p: ProjectVm) => (
                                <Select.Option key={p.id} value={p.id}>
                                    {p.name}
                                </Select.Option>
                            ))}
                    </Select>
                </Form.Item>

                {this.getDocumentTypeFormItems()}

                <Form.Item
                    name="comment"
                    label={translations.documents.comment}
                    initialValue={document?.comment}
                >
                    <TextArea />
                </Form.Item>

                {/* HIDDEN FIELDS */}

                <Form.Item name="id" initialValue={document?.id} hidden={true}>
                    <Input />
                </Form.Item>

                <Form.Item name="code" initialValue={document?.code} hidden={true}>
                    <Input />
                </Form.Item>

                <Form.Item name="documentTypeId" initialValue={typeId} hidden={true}>
                    <Input />
                </Form.Item>

                <Form.Item name="parentId" initialValue={document?.parentId} hidden={true}>
                    <Input />
                </Form.Item>

                <Form.Item name="currency" initialValue={document?.currency} hidden={true}>
                    <Input />
                </Form.Item>

                {!!documentItems?.length && (
                    <DocumentItemTable
                        documentTypeId={documentItems[0].documentTypeId}
                        documentItems={documentItems}
                        isSummary={true}
                    />
                )}
                {!!warehouseItems?.length && (
                    <DocumentItemTable
                        documentTypeId={typeId}
                        warehouseItems={warehouseItems}
                        isSummary={true}
                    />
                )}
            </Form>
        );
    }
}

export default DocumentForm;
