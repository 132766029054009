export const get = (obj: any, path: string): any => {
    const result = path.split('.').reduce((r, p) => {
        if (typeof r === 'object') {
            p = p.startsWith("[") ? p.replace(/\D/g, "") : p;

            return r[p];
        }

        return undefined;
    }, obj);

    return result;
};