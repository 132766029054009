import { Space, Select, DatePicker, Button, Drawer, PageHeader } from 'antd';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { translations } from '../../config/translations';
import { ActionType, ModuleName } from '../../core/models/enum';
import { authorizeAction } from '../../helpers/CheckPermissionHelper';
import {
    CompaniesClient,
    CompanyVm,
    SalariesClient,
    EmployeeSalaryVm,
    SalaryVm,
    ExportSalariesXlsxCommand,
} from '../../utils/api';
import { Props } from './index';
import { SelectOption } from '../../core/models/SelectOption';
import moment from 'moment';
import SelectMonth from '../../components/select-month/SelectMonth';
import SalaryTable from './salary-table';
import SalariesCopyForm from './copy-salaries-form';
import { defaultTablePagination } from '../../config/constants';
import { getFiltersParams, getPagingSortingParams } from '../../helpers/SearchParamsHelper';
import { capitalizeFirstLetter } from '../../helpers/StringHelper';
import { CopyOutlined, FileExcelOutlined } from '@ant-design/icons';
import { downloadFile } from '../../helpers/FileHelper';

interface State {
    allowCreate: boolean;
    companies: SelectOption[];
    employeeSalaries?: EmployeeSalaryVm[];
    totalItems: number;
    loading: boolean;
    loadingExport: boolean;
    recordCopyDrawerOpen: boolean;
    selectedCompany?: number;
    selectedMonth: number;
    selectedYear: number;
}

class Salaries extends React.Component<Props & RouteComponentProps, State> {
    private tableRef: any = React.createRef();

    currentYear = moment();

    public constructor(props: Props & RouteComponentProps) {
        super(props);

        this.state = {
            allowCreate: authorizeAction(
                props.userProfile,
                ModuleName.Cooperators,
                ActionType.Create
            ),
            companies: [],
            employeeSalaries: [],
            totalItems: 0,
            loading: false,
            loadingExport: false,
            recordCopyDrawerOpen: false,
            selectedCompany: this.getSelectedCompanyFromUrl() || 1, // Default to company ID 1
            selectedMonth: this.getSelectedMonthFromUrl(),
            selectedYear: this.getSelectedYearFromUrl(),
        };
    }

    public componentDidMount = () => {
        this.initializeDataFromUrl();
        this.getCompanies();
    };

    public componentDidUpdate(prevProps: Props & RouteComponentProps) {
        if (this.props.location.search !== prevProps.location.search) {
            this.initializeDataFromUrl();
        }
    }

    private initializeDataFromUrl = () => {
        const selectedCompany = this.getSelectedCompanyFromUrl();
        const selectedMonth = this.getSelectedMonthFromUrl();
        const selectedYear = this.getSelectedYearFromUrl();

        this.setState(
            { selectedCompany: selectedCompany ? selectedCompany : 1, selectedMonth, selectedYear },
            () => {
                this.getEmployeeSalaries();
            }
        );
    };

    private getSelectedCompanyFromUrl = (): number | undefined => {
        const params = new URLSearchParams(this.props.location.search);
        return params.get('companyId') ? parseInt(params.get('companyId')!) : 1;
    };

    private getSelectedMonthFromUrl = (): number => {
        const params = new URLSearchParams(this.props.location.search);
        return params.get('month')
            ? parseInt(params.get('month')!)
            : (moment().month() as number) + 1;
    };

    private getSelectedYearFromUrl = (): number => {
        const params = new URLSearchParams(this.props.location.search);
        return params.get('year') ? parseInt(params.get('year')!) : moment().year();
    };

    private updateQueryParams = (companyId?: number, month?: number, year?: number) => {
        const params = new URLSearchParams(this.props.location.search);
        if (companyId) {
            params.set('companyId', companyId.toString());
        }
        if (month) {
            params.set('month', month.toString());
        }
        if (year) {
            params.set('year', year.toString());
        }
        this.props.history.push({ search: params.toString() });
    };

    private getCompanies = async () => {
        const companies = (await new CompaniesClient().getAll()).map(
            (unit: CompanyVm): SelectOption => ({
                label: unit.name,
                value: unit.id,
            })
        );

        if (companies.length > 0) {
            const { selectedCompany, selectedMonth, selectedYear } = this.state;
            const companyToSelect = selectedCompany || companies[0].value;
            this.setState({ companies, selectedCompany: companyToSelect }, () => {
                this.updateQueryParams(companyToSelect, selectedMonth, selectedYear);
                this.getEmployeeSalaries();
            });
        }
    };

    private getEmployeeSalaries = async () => {
        const { selectedCompany, selectedMonth, selectedYear } = this.state;
        this.setState({ loading: true });

        const { location } = this.props;
        const params = new URLSearchParams(location.search);
        const { pageIndexParam, pageSizeParam, sortByParam, sortOrderParam } =
            getPagingSortingParams(params);
        const filters = getFiltersParams(params);

        try {
            const result = await new SalariesClient().getPaginated(
                filters.Employee_Name?.toString(),
                selectedCompany ? selectedCompany : 1,
                pageIndexParam && parseInt(pageIndexParam) ? parseInt(pageIndexParam) : 1,
                pageSizeParam && parseInt(pageSizeParam)
                    ? parseInt(pageSizeParam)
                    : defaultTablePagination.defaultPageSize,
                sortByParam ? capitalizeFirstLetter(sortByParam) : undefined,
                sortOrderParam,
                selectedMonth,
                selectedYear
            );

            this.setState({
                employeeSalaries: result.pageItems,
                totalItems: result.totalItems,
                loading: false,
            });
        } catch (error) {
            console.error(error);
            this.setState({ loading: false });
        }
    };

    private handleSelectMonth = (value: number) => {
        this.setState({ selectedMonth: value }, () => {
            this.updateQueryParams(this.state.selectedCompany, value, this.state.selectedYear);
            this.getEmployeeSalaries();
        });
    };

    private handleSelectCompany = (value: number | undefined) => {
        this.setState({ selectedCompany: value ? value : 1 }, () => {
            this.props.history.push({
                search: new URLSearchParams({
                    companyId: value?.toString() ?? '1',
                    month: this.state.selectedMonth.toString(),
                    year: this.state.selectedYear.toString(),
                    pageIndex: '1', // Reset page index to 1 when changing company
                }).toString(),
            });
            this.getEmployeeSalaries();
        });
    };

    private handleYearChange = (date: moment.Moment | null) => {
        const year = date ? date.year() : this.state.selectedYear;
        this.setState({ selectedYear: year }, () => {
            this.updateQueryParams(this.state.selectedCompany, this.state.selectedMonth, year);
            this.getEmployeeSalaries();
        });
    };

    private handleRecordsCopy = () => {
        this.setState({
            recordCopyDrawerOpen: true,
        });
    };

    private closeRecordsCopy = () => {
        this.setState({ recordCopyDrawerOpen: false });
    };

    private handleRecordsCopied = (newRecords: SalaryVm[]) => {
        const { employeeSalaries } = this.state;
        if (!employeeSalaries) return;

        this.setState({
            recordCopyDrawerOpen: false,
        });

        this.getEmployeeSalaries();
    };

    private handleExport = async (values: any) => {
        const { selectedCompany, selectedMonth, selectedYear } = this.state;

        this.setState({
            loadingExport: true,
        });

        const request: ExportSalariesXlsxCommand = new ExportSalariesXlsxCommand({
            companyId: selectedCompany || 1,
            month: selectedMonth,
            year: selectedYear,
        });

        let result = await new SalariesClient().exportSalaries(request);
        downloadFile(result);

        this.setState({
            loadingExport: false,
        });
    };

    public render(): React.ReactElement {
        const {
            allowCreate,
            companies,
            employeeSalaries,
            loading,
            recordCopyDrawerOpen,
            selectedCompany,
            selectedMonth,
            selectedYear,
        } = this.state;

        return (
            <>
                <PageHeader
                    title={translations.salaries.salaries}
                    extra={
                        allowCreate
                            ? [
                                  <Button
                                      key="1"
                                      type="primary"
                                      style={{
                                          zIndex: 10,
                                          float: 'right',
                                      }}
                                      className="no-print"
                                      onClick={this.handleRecordsCopy}
                                  >
                                      <CopyOutlined />
                                      {translations.salaries.copySalaries}
                                  </Button>,
                                  <Button
                                      key="2"
                                      style={{
                                          zIndex: 10,
                                          float: 'right',
                                      }}
                                      className="print"
                                      onClick={this.handleExport}
                                  >
                                      <FileExcelOutlined />
                                      {translations.salaries.export}
                                  </Button>,
                              ]
                            : []
                    }
                >
                    <Space direction="horizontal" size="large">
                        <div>
                            <span style={{ width: '30%', marginRight: '10px' }}>
                                {translations.salaries.company}:
                            </span>
                            <Select<number>
                                showSearch
                                placeholder={translations.employees.companyPlaceholder}
                                options={companies}
                                onSelect={this.handleSelectCompany}
                                value={selectedCompany}
                                style={{ width: '70%', minWidth: '200px' }}
                            />
                        </div>
                        <div>
                            <span style={{ width: '30%', marginRight: '10px' }}>
                                {translations.salaries.month}:
                            </span>
                            <SelectMonth
                                selectedMonth={selectedMonth}
                                onMonthSelected={this.handleSelectMonth}
                            />
                        </div>
                        <div>
                            <span style={{ width: '30%', marginRight: '10px' }}>
                                {translations.salaries.year}:
                            </span>
                            <DatePicker
                                picker="year"
                                value={moment(selectedYear, 'YYYY')}
                                onChange={(date) => this.handleYearChange(date)}
                                style={{ width: '70%' }}
                                allowClear={false}
                            />
                        </div>
                    </Space>
                </PageHeader>

                <SalaryTable
                    wrappedComponentRef={this.tableRef}
                    selectedCompanyId={selectedCompany}
                    selectedMonth={selectedMonth}
                    selectedYear={selectedYear}
                    employeeSalaries={employeeSalaries ? employeeSalaries : []}
                    loading={loading}
                    fetchEmployeeSalaries={this.getEmployeeSalaries}
                    totalCount={this.state.totalItems}
                />
                {recordCopyDrawerOpen && (
                    <Drawer
                        title={translations.workHourRecods.copyRecords}
                        open={!!recordCopyDrawerOpen}
                        onClose={this.closeRecordsCopy}
                        width={500}
                    >
                        <SalariesCopyForm
                            selectedCompanyId={selectedCompany}
                            selectedMonth={selectedMonth}
                            selectedYear={selectedYear}
                            onClose={this.closeRecordsCopy}
                            onSuccess={this.handleRecordsCopied}
                        />
                    </Drawer>
                )}
            </>
        );
    }
}

export default Salaries;
