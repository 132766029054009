import { DatePicker, Form, Select } from 'antd';
import React from 'react';
import DrawerButtons from '../../../components/drawer-buttons';
import {
    ApiException,
    CompaniesClient,
    CompanyVm,
    CopySalariesCommand,
    SalariesClient,
    SalaryVm,
} from '../../../utils/api';
import { Props } from '.';
import { translations } from '../../../config/translations';
import SelectMonth from '../../../components/select-month';
import moment from 'moment';
import { SelectOption } from '../../../core/models/SelectOption';
import { formItemLayout420 } from '../../../config/formLayouts';
import { showError, showSuccess } from '../../../helpers/NotificationHelper';

interface State {
    isSaving: boolean;
    selectedCompany?: number;
    selectedFromMonth: number;
    selectedFromYear: number;
    selectedToMonth: number;
    selectedToYear: number;
    companies: SelectOption[];
}

class SalariesCopyForm extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            isSaving: false,
            selectedCompany: 1,
            selectedFromMonth: moment().month() + 1, // Moment month is 0-indexed, so add 1
            selectedFromYear: moment().year(),
            selectedToMonth: moment().add(1, 'month').month() + 1, // Moment month is 0-indexed, so add 2 to get next month
            selectedToYear: moment().year(),
            companies: [],
        };
    }

    public componentDidMount = () => {
        this.getCompanies();
    };

    private getCompanies = async () => {
        const companies = (await new CompaniesClient().getAll()).map(
            (unit: CompanyVm): SelectOption => ({
                label: unit.name,
                value: unit.id,
            })
        );

        if (companies.length > 0) {
            this.setState({
                companies: companies,
                selectedCompany: companies[0].value ? companies[0].value : 1, // Initially select the first company
            });
        }
    };

    private handleSelectFromMonth = (value: number) => {
        this.setState({
            selectedFromMonth: value,
        });
    };

    private handleSelectToMonth = (value: number) => {
        this.setState({
            selectedToMonth: value,
        });
    };

    private handleSubmit = async (values: any) => {
        const {
            selectedCompany,
            selectedFromMonth,
            selectedFromYear,
            selectedToMonth,
            selectedToYear,
        } = this.state;

        this.setState({
            isSaving: true,
        });

        const request: CopySalariesCommand = new CopySalariesCommand({
            companyId: selectedCompany || 1,
            fromMonth: selectedFromMonth,
            fromYear: selectedFromYear,
            toMonth: selectedToMonth,
            toYear: selectedToYear,
        });

        if (selectedCompany && selectedFromMonth && selectedFromYear) {
            const client = new SalariesClient();
            try {
                const result = await client.copyBatch(request);

                this.handleSuccess(result);
            } catch (error) {
                if (error instanceof ApiException) {
                    showError(error.response);
                } else {
                    showError(translations.general.errorSavingData);
                }
            }
        } else {
            showError(translations.general.sameDatesInvalid);
        }

        this.setState({
            isSaving: false,
        });
    };

    private handleSuccess = (result: SalaryVm[]) => {
        const { onSuccess } = this.props;

        showSuccess(translations.salaries.successfullyCopied);

        onSuccess(result);
    };

    public render(): React.ReactElement {
        const { onClose } = this.props;
        const {
            isSaving,
            companies,
            selectedCompany,
            selectedFromMonth,
            selectedFromYear,
            selectedToMonth,
            selectedToYear,
        } = this.state;

        return (
            <Form
                onFinish={this.handleSubmit}
                style={{ textAlign: 'end' }}
                title={translations.salaries.copySalaries}
                {...formItemLayout420}
                initialValues={{
                    selectedCompany,
                    copyFromMonth: selectedFromMonth,
                    copyFromYear: moment(selectedFromYear, 'YYYY'),
                    copyToMonth: selectedToMonth,
                    copyToYear: moment(selectedToYear, 'YYYY'),
                }}
            >
                <DrawerButtons
                    isSaving={isSaving}
                    onCancelAction={onClose}
                    submitText={translations.salaries.copy}
                />

                <Form.Item
                    name="company"
                    label={translations.salaries.company}
                    initialValue={1}
                    rules={[
                        {
                            required: true,
                            message: translations.general.requiredField,
                        },
                    ]}
                >
                    <Select<number>
                        showSearch
                        allowClear
                        placeholder={translations.employees.companyPlaceholder}
                        options={companies}
                        onSelect={(value) => this.setState({ selectedCompany: value })}
                        value={selectedCompany ? selectedCompany : 1}
                        style={{ width: '100%', textAlign: 'left' }}
                    />
                </Form.Item>

                <Form.Item
                    label={translations.general.copyFrom}
                    style={{ marginBottom: 0 }}
                    required
                >
                    <Form.Item
                        name="copyFromMonth"
                        style={{ display: 'inline-block', width: 'calc(50% - 4px)' }}
                        rules={[
                            {
                                required: true,
                                message: translations.general.requiredField,
                            },
                        ]}
                    >
                        <SelectMonth
                            selectedMonth={selectedFromMonth}
                            onMonthSelected={this.handleSelectFromMonth}
                            width={'100%'}
                        />
                    </Form.Item>
                    <Form.Item
                        name="copyFromYear"
                        style={{
                            display: 'inline-block',
                            width: 'calc(50% - 4px)',
                            marginLeft: '8px',
                        }}
                        rules={[
                            {
                                required: true,
                                message: translations.general.requiredField,
                            },
                        ]}
                    >
                        <DatePicker
                            picker="year"
                            value={moment(selectedFromYear, 'YYYY')}
                            onChange={(date) =>
                                this.setState({
                                    selectedFromYear: date ? date.year() : moment().year(),
                                })
                            }
                            style={{ width: '100%' }}
                        />
                    </Form.Item>
                </Form.Item>

                <Form.Item label={translations.general.copyTo} style={{ marginBottom: 0 }} required>
                    <Form.Item
                        name="copyToMonth"
                        style={{ display: 'inline-block', width: 'calc(50% - 4px)' }}
                        rules={[
                            {
                                required: true,
                                message: translations.general.requiredField,
                            },
                        ]}
                    >
                        <SelectMonth
                            selectedMonth={selectedToMonth}
                            onMonthSelected={this.handleSelectToMonth}
                            width={'100%'}
                        />
                    </Form.Item>
                    <Form.Item
                        name="copyToYear"
                        style={{
                            display: 'inline-block',
                            width: 'calc(50% - 4px)',
                            marginLeft: '8px',
                        }}
                        rules={[
                            {
                                required: true,
                                message: translations.general.requiredField,
                            },
                        ]}
                    >
                        <DatePicker
                            picker="year"
                            value={moment(selectedToYear, 'YYYY')}
                            onChange={(date) =>
                                this.setState({
                                    selectedToYear: date ? date.year() : moment().year(),
                                })
                            }
                            style={{ width: '100%' }}
                        />
                    </Form.Item>
                </Form.Item>
            </Form>
        );
    }
}

export default SalariesCopyForm;
