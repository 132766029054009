import { Spin } from 'antd';
import React from 'react';
import './styles.css';

class LoadingOverlay extends React.PureComponent {
    public render(): React.ReactElement {
        return (
            <div className="loading">
                <Spin size="large" />
            </div>
        );
    }
}

export default LoadingOverlay;
