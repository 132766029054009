import { PlusOutlined } from '@ant-design/icons';
import { Button, Drawer, PageHeader, Tabs } from 'antd';
import React from 'react';
import { defaultTablePagination, DRAWER_WIDTH } from '../../config/constants';
import Routes from '../../config/routes';
import { translations } from '../../config/translations';
import { ActionType, DrawerState, ModuleName } from '../../core/models/enum';
import { authorizeAction } from '../../helpers/CheckPermissionHelper';
import { EmployeeVm } from '../../utils/api';
import EmployeeForm from './employee-form';
import EmployeeTable from './employee-table';
import { Props } from './index';

interface State {
    allowCreate: boolean;
    drawerState: DrawerState;
}

class Employees extends React.Component<Props, State> {
    private tableRef: any = React.createRef();

    public constructor(props: Props) {
        super(props);

        const {
            match: { path }
        } = props;

        this.state = {
            allowCreate: authorizeAction(
                props.userProfile,
                ModuleName.Cooperators,
                ActionType.Create
            ),
            drawerState:
                path === Routes.ROUTE_EMPLOYEES_NEW ? DrawerState.Edit : DrawerState.Closed,
        };
    }

    private handleDrawerMode = (drawerState: DrawerState) => {
        const { history } = this.props;

        this.setState({
            drawerState,
        });

        history.push(
            drawerState === DrawerState.Create ? Routes.ROUTE_EMPLOYEES_NEW : Routes.ROUTE_EMPLOYEES
        );
    };

    private handleSuccessCreate = (_: EmployeeVm) => {
        this.handleDrawerMode(DrawerState.Closed);
        this.tableRef.current.getEmployees(defaultTablePagination);
    };

    private handleShowInactiveToggle = async (keyActive: string) => {
        const { history, location } = this.props;
        const params = new URLSearchParams(location.search);

        if (params.has('activeTab')) {
            params.set('activeTab', keyActive);
        } else {
            params.append('activeTab', keyActive);
        }

        history.push({
            pathname: history.location.pathname,
            search: params.toString(),
        });
    };

    public render(): React.ReactElement {
        const { drawerState, allowCreate } = this.state;
        const { location } = this.props;
        const params = new URLSearchParams(location.search);
        const activeTab = params.get('activeTab') ? params.get('activeTab') : 'active';

        return (
            <>
                <PageHeader
                    title={translations.employees.employees}
                    extra={
                        allowCreate
                            ? [
                                  <Button
                                      key="1"
                                      type="primary"
                                      style={{
                                          zIndex: 10,
                                          float: 'right',
                                      }}
                                      onClick={() => this.handleDrawerMode(DrawerState.Create)}
                                      className="no-print"
                                  >
                                      <PlusOutlined />
                                      {translations.employees.addEmployee}
                                  </Button>,
                              ]
                            : []
                    }
                >
                    <Tabs
                        type="editable-card"
                        activeKey={activeTab ? activeTab : 'active'}
                        onTabClick={(key: string) => this.handleShowInactiveToggle(key)}
                        hideAdd
                        destroyInactiveTabPane={true}
                        items={[
                            { label: 'Aktivni', key: 'active', closable: false },
                            { label: 'Neaktivni', key: 'inactive', closable: false },
                        ]}
                    />
                </PageHeader>

                <EmployeeTable
                    wrappedComponentRef={this.tableRef}
                />

                <Drawer
                    title={translations.employees.addEmployee}
                    open={!!drawerState}
                    onClose={() => this.handleDrawerMode(DrawerState.Closed)}
                    width={DRAWER_WIDTH}
                    destroyOnClose
                >
                    <EmployeeForm
                        onClose={() => this.handleDrawerMode(DrawerState.Closed)}
                        onSuccess={this.handleSuccessCreate}
                    />
                </Drawer>
            </>
        );
    }
}

export default Employees;
